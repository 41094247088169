import {
	Character,
	CharacterAttachable,
	CharacterAttachableDTO,
	CharacterDetail,
	CharacterFeature,
	CharacterFeatureDTO,
	CharacterMutationDTO,
	CharacterProperty,
	CharacterValueDTO,
} from '../types/character.type'
import { Entity } from '../types/strapi/entity.type'

import * as mathjs from 'mathjs'

type CharacterDetailDTO = Omit<CharacterDetail, keyof Entity>

export default class CharacterInstance {
	_character: Partial<Character> & {
		property: CharacterProperty
		features: CharacterFeature[]
	} = {
		property: {},
		features: [],
	}
	_characterDetail: CharacterDetailDTO = { attachables: [] }

	_attachables: CharacterAttachableDTO[] = []
	_features: CharacterFeatureDTO[] = []
	_mutations: CharacterMutationDTO[] = []
	_propertyDetail: Record<string, CharacterValueDTO> = {}

	_isCompiled = false
	_isUpdated = false

	_mathJsParser: mathjs.Parser

	constructor() {
		this._mathJsParser = mathjs.parser()
		this.initialize()
	}

	initialize(
		character?: Partial<Character>,
		characterDetail?: CharacterDetailDTO
	): void {
		this._character = {
			property: {},
			features: [],
			...character,
		}
		this._characterDetail = {
			...characterDetail,
			attachables: [...(characterDetail?.attachables.filter((x) => !!x) || [])],
		}
		this._mathJsParser.clear()
		this._isCompiled = false
		this._isUpdated = false

		this.compile()
		this.reevaluate()
	}

	compile(): void {
		if (this._isCompiled) {
			return
		}

		this._attachables = []
		this._features = []
		this._mutations = []

		for (const { origin, override } of this._characterDetail.attachables) {
			const attachable = {
				...origin,
				...override,
				features: [...(origin?.features || [])],
			} as CharacterAttachable

			for (const overrideFeature of override?.features || []) {
				if (!overrideFeature.overridedCode) {
					attachable.features.push(overrideFeature)
				} else {
					const index = attachable.features.findIndex(
						(f) => f.code === overrideFeature.overridedCode
					)
					if (index === -1) {
						// attachable.features.push(overrideFeature)
					} else {
						attachable.features.splice(index, 1, overrideFeature)
					}
				}
			}

			const newAttachable: CharacterAttachableDTO = {
				...attachable,
				features: [],
			}

			attachable.features.forEach((feature) => {
				if (feature.options && Array.isArray(feature.options)) {
					const _mutations = []
					for (
						let i = 0;
						i < (feature.options?.length || 0) && i < (feature.amount || 1);
						i++
					) {
						_mutations.push(...(feature.options?.[i].mutations || []))
						feature = {
							...feature,
							...feature.options?.[i],
						}
					}
					feature.mutations = _mutations
					feature.options = []
				}

				const { mutations, type, ...featureOthers } = feature

				const newFeature: CharacterFeatureDTO = {
					...featureOthers,
					type: type || 'static',
					attachable: newAttachable,
					mutations: [],
					isActive: type !== 'triggered' && type !== 'activated',
				}

				;(mutations || []).forEach((mutation) => {
					const { type, ...mutationOthers } = mutation

					const newMutation: CharacterMutationDTO = {
						...mutationOthers,
						type: type || 'permanent',
						attachable: newAttachable,
						feature: newFeature,
					}

					newFeature.mutations.push(newMutation)
				})

				newAttachable.features.push(newFeature)
			})

			this._attachables.push(newAttachable)
			this._features = [...this._features, ...newAttachable.features]
			this._mutations.push(
				...([] as CharacterMutationDTO[]).concat(
					...newAttachable.features.map((f) => f.mutations)
				)
			)
		}

		// compile keys' formulas
		this._character.property = {}
		this._propertyDetail = {}
		const wildcardKeys = []

		for (const m of this._mutations) {
			if (this._propertyDetail[m.key]) {
				continue
			}

			if (m.key.indexOf('$any') !== -1) {
				wildcardKeys.push(m.key)
			}

			const relatedMutations = this._mutations.filter((rM) => rM.key === m.key)

			let constant: string | undefined = '0'
			const changers = []
			let isArray = false
			let overallFormula = '0'

			if (relatedMutations.length > 0) {
				const { formula } = relatedMutations[0]
				const op = formula[0]
				const node = mathjs.parse(
					op === '+' || op === '-' ? formula.substr(1) : formula
				)
				if (node.isConstantNode && isNaN(parseInt(node.value))) {
					isArray = true
					constant = undefined
				}
			}

			for (const rM of relatedMutations) {
				const { formula } = rM
				if (['+', '-', '*', '/'].indexOf(formula[0]) !== -1) {
					if (isArray) {
						const op = formula[0]
						const result = formula.substr(1)
						const index = changers.indexOf(result)

						if (op === '+' && index === -1) {
							changers.push(result)
						} else if (op === '-' && index !== -1) {
							changers.splice(index, 1)
						}
					} else {
						changers.push(formula)
					}
				} else {
					constant = formula
				}
			}

			if (isArray) {
				if (changers.length === 0) {
					overallFormula = constant || ''
				} else {
					if (constant) {
						changers.unshift(constant)
					}
					overallFormula = JSON.stringify(changers)
				}
			} else {
				overallFormula = changers.reduce(
					(prev, curr) => `(${prev}${curr})`,
					constant || ''
				)
			}

			const dependencies: string[] = []
			mathjs.parse(overallFormula).traverse((n, _, parent) => {
				if (
					n.isSymbolNode &&
					(!parent?.isFunctionNode || (parent?.fn as unknown) !== n)
				) {
					dependencies.push(n.name as string)
				}
			})

			this._propertyDetail[m.key] = {
				key: m.key,
				formula: overallFormula,
				value: 0,
				dependencies,
				mutations: relatedMutations,
			}
		}

		// handle wildcard keys
		for (const wcKey of wildcardKeys) {
			const detail = this._propertyDetail[wcKey]

			const anyReplacements: string[] = []
			const deps = detail.dependencies.filter((dep) => dep.indexOf('$any'))

			for (const dep of deps) {
				const pattern = new RegExp(dep.replace(/\$any/g, '(.+)'))
				for (const detailKey in this._propertyDetail) {
					const m = pattern.exec(detailKey)
					if (
						m &&
						m.length >= 2 &&
						m[1] !== '$any' &&
						anyReplacements.indexOf(m[1])
					) {
						anyReplacements.push(m[1])
					}
				}
			}

			for (const anyReplacement of anyReplacements) {
				const newKey = detail.key.replace(/\$any/g, anyReplacement)
				const newDetail: CharacterValueDTO = {
					key: newKey,
					formula: detail.formula.replace(/\$any/g, anyReplacement),
					dependencies: detail.dependencies.map((dep) =>
						dep.replace(/\$any/g, anyReplacement)
					),
					mutations: this._mutations.filter(
						(m) => m.key === detail.key || m.key === newKey
					),
					value: 0,
				}

				this._propertyDetail[newKey] = newDetail
			}
		}

		this._character.features = this._features.map(
			({
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				mutations,
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				amount,
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				canRepeat,
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				options,
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				attachable,
				...featureOthers
			}) => ({ ...featureOthers, sourceCateogry: attachable.category })
		)

		this._isCompiled = true
		this._isUpdated = false
	}

	reevaluate(): void {
		if (!this._isCompiled) {
			this.compile()
		}

		if (!this._isUpdated) {
			Object.keys(this._propertyDetail).forEach((key) => {
				this.reevaluateByKey(key, [])
			})

			this._isUpdated = true
		}
	}

	reevaluateByKey(key: string, path: string[]): void {
		if (path.indexOf(key) !== -1) {
			return
		}

		if (this._mathJsParser.get(key) == undefined) {
			this._mathJsParser.set(key, 0)
			this._character.property[key] = 0
		}

		const detail = this._propertyDetail[key]

		if (detail) {
			for (const dep of detail.dependencies) {
				if (this._mathJsParser.get(dep) == undefined) {
					this._mathJsParser.set(dep, 0)
					this._character.property[dep] = 0
				}
			}

			const result = this._mathJsParser.evaluate(`${detail.formula}`)
			const resultValue =
				result._data?.map((item: unknown) => {
					if (typeof item === 'string') {
						return item.replace(/\"/g, '')
					}

					return item
				}) || result

			this._mathJsParser.set(key, resultValue)
			this._propertyDetail[key].value = resultValue
			this._character.property[key] = resultValue
		}

		Object.values(this._propertyDetail)
			.filter((d) => d.dependencies.indexOf(key) !== -1)
			.forEach((d) => this.reevaluateByKey(d.key, [...path, key]))
	}

	addAttachable(attachable: CharacterAttachable): CharacterInstance {
		this._characterDetail.attachables.push({ origin: attachable })
		this._isCompiled = false
		this._isUpdated = false
		return this
	}

	getCharacter(): Partial<Character> {
		return this._character
	}

	getCharacterDetail(): Partial<CharacterDetail> {
		return this._characterDetail
	}

	getProperty(): CharacterProperty {
		if (!this._isUpdated) {
			this.reevaluate()
		}
		return this._character.property
	}

	getFeatures(): CharacterFeature[] {
		if (!this._isUpdated) {
			this.reevaluate()
		}
		return this._character.features
	}

	getPropertyAsObject(): CharacterProperty {
		return this.getProperty()
	}
}
