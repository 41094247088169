/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { RouteType } from '../types/system/route.type'

import CharacterBuilder from '../views/CharacterBuilder'
import Character from '../views/CharacterDetail'
import Characters from '../views/Characters'
import Dashboard from '../views/Dashboard'
import RandomNpc from '../views/RandomNpc'

const routes: RouteType[] = [
	{
		path: '/admin/dashboard',
		name: '儀表板',
		icon: {
			category: 'ra',
			icon: 'compass',
		},
		component: Dashboard,
		inSidebar: true,
	},
	{
		path: '/admin/characters/builder',
		name: '角色',
		icon: {
			category: 'ra',
			icon: 'helmet',
		},
		component: CharacterBuilder,
	},
	{
		path: '/admin/characters/:characterId',
		name: '角色詳細',
		icon: {
			category: 'ra',
			icon: 'helmet',
		},
		component: Character,
	},
	{
		path: '/admin/characters',
		name: '我的角色',
		icon: {
			category: 'ra',
			icon: 'helmet',
		},
		component: Characters,
		inSidebar: true,
	},
]
export default routes
