import React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
	children: React.ReactNode
}
const PageTitle = ({ children }: Props): JSX.Element => (
	<Helmet>
		<title>{children}</title>
	</Helmet>
)

export default React.memo(PageTitle)
