import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

const StyledDiv = styled.div`
	padding: 1em;
	font-size: 0.9rem;
	margin-bottom: 1rem;
	border: 1px solid rgba(0, 0, 0, 0.3);
	background-color: rgba(0, 0, 0, 0.1);

	&.helper-box-warning {
		border-color: rgba(251, 198, 88, 0.3);
		background-color: rgba(251, 198, 88, 0.1);
	}

	&.helper-box-info {
		border-color: rgba(81, 188, 218, 0.3);
		background-color: rgba(81, 188, 218, 0.1);
	}

	&.helper-box-success {
		border-color: rgba(107, 208, 152, 0.3);
		background-color: rgba(107, 208, 152, 0.1);
	}

	&.helper-box-disabled {
		border-color: rgba(102, 102, 102, 0.3);
		background-color: rgba(102, 102, 102, 0.1);
	}
`

type Props = React.HTMLAttributes<HTMLDivElement> & {
	color?:
		| 'primary'
		| 'secondary'
		| 'light'
		| 'dark'
		| 'info'
		| 'warning'
		| 'success'
		| 'disabled'
}

const HelperBox = ({
	className,
	color = 'primary',
	...others
}: Props): JSX.Element => {
	return (
		<StyledDiv
			className={classnames(className, `helper-box-${color}`)}
			{...others}
		/>
	)
}

export default React.memo(HelperBox)
