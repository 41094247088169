import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
	useHistory as useRouterHistory,
	useParams as useRouterParams,
	useRouteMatch,
} from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import classnames from 'classnames'

import CharacterInstance from '../../instances/character.instance'
import {
	Character,
	CharacterDetail,
	CharacterDetailAttachableOriginAndOverride,
} from '../../types/character.type'

import {
	getCharacterDetailById,
	postCharacter,
	postCharacterDetailById,
	putCharacter,
	putCharacterDetailById,
} from '../../helpers/api.helper'

import { Button, Nav, NavItem, NavLink } from 'reactstrap'
import BiIcon from '../../components/Icon/BiIcon'

import CBBackgrounds from './CBBackgrounds'
import CBBio from './CBBio'
import CBClses from './CBClses'
import CBContext, { CBContextProps } from './CBContext'
import CBPointbuy from './CBPointbuy'
import CBRaces from './CBRaces'
import CBRulesets from './CBRulesets'

enum Tab {
	Race,
	Cls,
	Background,
	Pointbuy,
	Bio,
	Final,
}

const NavContainer = styled.div`
	margin-top: 16px;

	> * {
		display: none;

		&.show {
			display: block;
		}
	}
`

const StyledFinalDiv = styled.div`
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
	padding: 2em;
	border-radius: 1em;
	background-color: rgba(82, 77, 47, 0.08);
`

const ValidIcon = styled(() => <BiIcon icon='check' />)`
	color: #6bd098;
`

const InvalidIcon = styled(() => <BiIcon icon='exclamation-diamond' />)`
	color: #ef8157;
`

const CharacterBuilder = (): JSX.Element => {
	const { id } = useRouterParams<{ id: string }>()

	const routeMatch = useRouteMatch()
	const routerHistory = useRouterHistory()

	const queryClient = useQueryClient()

	const [context, setContext] = useState<CBContextProps>({})
	const [ci] = useState<CharacterInstance>(new CharacterInstance())
	const [activeTab, setActiveTab] = useState<Tab>(Tab.Final)

	const characterDetailQuery = useQuery(
		['character', id, 'detail'],
		() => getCharacterDetailById(id),
		{
			enabled: id !== 'new',
			cacheTime: 10,
			staleTime: Infinity,
			refetchOnMount: 'always',
			select: (characterDetail: CharacterDetail) => {
				setContext((prev) => ({
					...prev,
					initRulesetAttachableOAO: characterDetail.attachables.find(
						(a) => a.origin?.category === 'ruleset'
					),
					initRaceAttachableOAO: characterDetail.attachables.find(
						(a) => a.origin?.category === 'race'
					),
					initClsAttachableOAO: characterDetail.attachables.find(
						(a) => a.origin?.category === 'cls'
					),
					initBackgroundAttachableOAO: characterDetail.attachables.find(
						(a) => a.origin?.category === 'background'
					),
					initPointbuyAttachableOAO: characterDetail.attachables.find(
						(a) => a.override?.category === 'pointbuy'
					),
					initBioAttachableOAO: characterDetail.attachables.find(
						(a) => a.override?.category === 'bio'
					),
				}))
			},
		}
	)

	const isRulesetError = useMemo(
		() => !context.ruleset || !context.rulesetAttachableOAO,
		[context.ruleset, context.rulesetAttachableOAO]
	)
	const isRaceError = useMemo(
		() => !context.race || !context.raceAttachableOAO,
		[context.race, context.raceAttachableOAO]
	)
	const isClsError = useMemo(() => !context.cls || !context.clsAttachableOAO, [
		context.cls,
		context.clsAttachableOAO,
	])
	const isBackgroundError = useMemo(
		() => !context.background || !context.backgroundAttachableOAO,
		[context.background, context.backgroundAttachableOAO]
	)
	const isPointbuyError = useMemo(
		() => !context.pointbuy || !context.pointbuyAttachableOAO,
		[context.pointbuy, context.pointbuyAttachableOAO]
	)
	const isBioError = useMemo(() => !context.bio || !context.bioAttachableOAO, [
		context.bio,
		context.bioAttachableOAO,
	])

	const isAnyError = useMemo(
		() =>
			isRulesetError ||
			isRaceError ||
			isClsError ||
			isBackgroundError ||
			isPointbuyError ||
			isBioError,
		[
			isRulesetError,
			isRaceError,
			isClsError,
			isBackgroundError,
			isPointbuyError,
			isBioError,
		]
	)

	const createOrUpdateCharacterMutation = useMutation(
		async (data: {
			character: Partial<Character>
			characterDetail: Partial<CharacterDetail>
		}) => {
			const _characterBody = {
				...data.character,
				portrait: data.character.portrait?.id,
				coverImage: data.character.coverImage?.id,
				city: data.character.city?.id,
				race: data.character.race?.id,
				clses: data.character.clses?.map((item) => item.id),
				background: data.character.background?.id,
				ruleset: data.character.ruleset?.id,
			}
			const _characterDetailBody = {
				attachables:
					data.characterDetail.attachables?.map((oao) => ({
						origin: oao.origin?.id,
						override: oao.override,
					})) || [],
			}

			if (id === 'new') {
				return postCharacter(_characterBody).then((character: Character) =>
					postCharacterDetailById(character.id, _characterDetailBody).then(
						() => character
					)
				)
			} else {
				return putCharacter(id, _characterBody).then((character: Character) =>
					putCharacterDetailById(character.id, _characterDetailBody).then(
						() => character
					)
				)
			}
		},
		{
			onSuccess: (createdOrUpdatedCharacter: Character) => {
				queryClient.invalidateQueries([
					'character',
					createdOrUpdatedCharacter.id,
				])
				routerHistory.replace(
					`/${routeMatch.url.split('/')[1]}/characters/${
						createdOrUpdatedCharacter.id
					}`
				)
				toast.success(id === 'new' ? '成功建立新角色！' : '已更新角色！')
			},
		}
	)

	const handleCreateCharacter = useCallback(() => {
		if (isAnyError) {
			return
		}

		createOrUpdateCharacterMutation.mutate({
			character: ci.getCharacter(),
			characterDetail: ci.getCharacterDetail(),
		})
	}, [isAnyError, createOrUpdateCharacterMutation])

	useEffect(() => {
		ci.initialize(
			{
				name: context.bio?.name,
				race: context.race,
				clses: context.cls ? [context.cls] : undefined,
				background: context.background,
				ruleset: context.ruleset,
				type: 'PC',
			},
			{
				attachables: [
					context.rulesetAttachableOAO as CharacterDetailAttachableOriginAndOverride,
					context.pointbuyAttachableOAO as CharacterDetailAttachableOriginAndOverride,
					context.bioAttachableOAO as CharacterDetailAttachableOriginAndOverride,
					context.raceAttachableOAO as CharacterDetailAttachableOriginAndOverride,
					context.clsAttachableOAO as CharacterDetailAttachableOriginAndOverride,
					context.backgroundAttachableOAO as CharacterDetailAttachableOriginAndOverride,
				],
			}
		)
		console.log(ci)
	}, [context])

	if (characterDetailQuery.isError) {
		return <div className='content'>Loading...</div>
	}

	if (characterDetailQuery.isError) {
		return <div className='content'>Error!</div>
	}

	return (
		<>
			<CBContext.Provider
				value={{
					context,
					setContext,
					ci,
				}}
			>
				<div className='content'>
					<Nav tabs fill pills>
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === Tab.Race })}
								onClick={() => setActiveTab(Tab.Race)}
							>
								{isRaceError ? <InvalidIcon /> : <ValidIcon />}
								種族: {context.race?.name || '(未選擇)'}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === Tab.Cls })}
								onClick={() => setActiveTab(Tab.Cls)}
							>
								{isClsError ? <InvalidIcon /> : <ValidIcon />}
								職業: {context.cls?.name || '(未選擇)'}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === Tab.Background })}
								onClick={() => setActiveTab(Tab.Background)}
							>
								{isBackgroundError ? <InvalidIcon /> : <ValidIcon />}
								背景: {context.background?.name || '(未選擇)'}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({
									active: activeTab === Tab.Pointbuy,
								})}
								onClick={() => setActiveTab(Tab.Pointbuy)}
							>
								{isPointbuyError ? <InvalidIcon /> : <ValidIcon />}
								屬性點配置
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({
									active: activeTab === Tab.Bio,
								})}
								onClick={() => setActiveTab(Tab.Bio)}
							>
								{isBioError ? <InvalidIcon /> : <ValidIcon />}
								人物設定
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({
									active: activeTab === Tab.Final,
								})}
								onClick={() => setActiveTab(Tab.Final)}
							>
								總結
							</NavLink>
						</NavItem>
					</Nav>

					<NavContainer>
						<div
							className={classnames({
								show: activeTab === Tab.Race,
							})}
						>
							<CBRaces />
						</div>
						<div
							className={classnames({
								show: activeTab === Tab.Cls,
							})}
						>
							<CBClses />
						</div>
						<div
							className={classnames({
								show: activeTab === Tab.Background,
							})}
						>
							<CBBackgrounds />
						</div>
						<div
							className={classnames({
								show: activeTab === Tab.Pointbuy,
							})}
						>
							<CBPointbuy />
						</div>
						<div
							className={classnames({
								show: activeTab === Tab.Bio,
							})}
						>
							<CBBio />
						</div>
						<div
							className={classnames({
								show: activeTab === Tab.Final,
							})}
						>
							<CBRulesets />
							<StyledFinalDiv>
								<p>
									請填寫上面全面分頁的資料，最後回到這裡，點擊下方的{' '}
									<strong>完成</strong> 按鈕來儲存你的角色。
								</p>
								<p>
									未完成或有錯誤的分頁會以 <InvalidIcon /> 標示；已完成的則以{' '}
									<ValidIcon /> 標示。
								</p>
								<div className='text-center'>
									<Button
										color='primary'
										size='lg'
										disabled={isAnyError}
										onClick={handleCreateCharacter}
									>
										完成
									</Button>
								</div>
							</StyledFinalDiv>
						</div>
					</NavContainer>
				</div>
			</CBContext.Provider>
		</>
	)
}

export default CharacterBuilder
