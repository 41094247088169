import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

import {
	CharacterFeature,
	CharacterFeatureQuality,
	CharacterFeatureType,
	CharacterFeatureUnit,
	CharacterMutation,
} from '../../types/character.type'

import { Card, CardBody, CardHeader } from 'reactstrap'
import StyledReactSelect from '../../components/Select/StyledReactSelect'

const FeatureCard = styled(Card)``
const FeatureCardHeader = styled(CardHeader)`
	&.card-header {
		display: flex;
		justify-content: space-between;
		font-size: 0.9em;
		color: rgba(0, 0, 0, 0.6);
		background-color: #51ccce;
		padding: 5px 15px 3px;

		.fc-quality-mundane & {
			background: #ddd;
		}
	}
`
const FeatureCardHeaderLeft = styled.div`
	font-weight: bold;
	flex: 1;
`
const FeatureCardHeaderRight = styled.div`
	display: flex;
	flex-wrap: nowrap;
	color: rgba(0, 0, 0, 0.4);
	font-size: 0.9em;
	> * {
		margin-left: 0.5rem;
	}
`
const FeatureCardBody = styled(CardBody)`
	&.card-body {
		padding: 10px 15px;
		text-align: justify;
	}
`

const FeatureOptionsSelect = styled(StyledReactSelect)`
	div.styled-react-select__control {
		background: transparent;
		border: none;
		border-radius: 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);
		color: rgba(0, 0, 0, 0.6);
		font-size: 1em;
		min-height: 0;
	}

	div.styled-react-select__dropdown-indicator,
	div.styled-react-select__clear-indicator {
		padding-top: 0;
		padding-bottom: 0;
		color: rgba(0, 0, 0, 0.6);
	}

	span.styled-react-select__indicator-separator {
		margin-top: 2px;
		margin-bottom: 2px;
		background-color: rgba(0, 0, 0, 0.25);
	}

	div.styled-react-select__value-container {
		padding-left: 0;
		padding-top: 0;
		padding-bottom: 0;

		> div[class*='-Input'] {
			margin: 0;
			padding: 0;
		}

		div.styled-react-select__single-value {
			color: rgba(0, 0, 0, 0.6);
			margin-left: 0;
			margin-right: 0;
		}

		div.styled-react-select__multi-value__label {
			padding: 0 3px 0 6px;
			color: rgba(0, 0, 0, 0.6);
		}
	}

	div.styled-react-select__placeholder {
		color: rgba(0, 0, 0, 0.6);
	}
`

type Props = {
	feature: CharacterFeature
	initOverridedFeature?: CharacterFeature
	onChange?: (f: CharacterFeature) => void
}

const typeToString = (type: CharacterFeatureType | undefined): string => {
	switch (type) {
		case 'activated':
			return '主動'
		case 'triggered':
			return '被動'
		case 'static':
			return '長駐'
		case 'improvement':
			return '提昇'
		case 'metadata':
			return '補充資料'
	}

	return '長駐'
}

const costToString = ({
	quantity,
	unit,
}: {
	quantity: number | string
	unit: CharacterFeatureUnit
}): string => {
	let unitString = ''
	switch (unit) {
		case 'action':
			unitString = '動作'
			break
		case 'reaction':
			unitString = '反應'
			break
		case 'bonusAction':
			unitString = '附贈動作'
			break
		case 'turn':
			unitString = '回合'
			break
		case 'round':
			unitString = '輪'
			break
		case 'minute':
			unitString = '分數'
			break
		case 'hour':
			unitString = '小時'
			break
		case 'day':
			unitString = '日'
			break
		case 'shortRest':
			unitString = '短休'
			break
		case 'longRest':
			unitString = '長休'
			break
		default:
			unitString = unit
	}

	return `${quantity} ${unitString}`
}

const chargeToString = ({
	quantity,
	per,
}: {
	quantity: number | string
	per: CharacterFeatureUnit
}): string => {
	let unitString = ''
	switch (per) {
		case 'action':
			unitString = '動作'
			break
		case 'reaction':
			unitString = '反應'
			break
		case 'bonusAction':
			unitString = '附贈動作'
			break
		case 'turn':
			unitString = '回合'
			break
		case 'round':
			unitString = '輪'
			break
		case 'minute':
			unitString = '分數'
			break
		case 'hour':
			unitString = '小時'
			break
		case 'day':
			unitString = '日'
			break
		case 'shortRest':
			unitString = '短休'
			break
		case 'longRest':
			unitString = '長休'
			break
		default:
			unitString = per
	}

	return `${quantity}充能(每${unitString})`
}

const qualityToString = (quality: CharacterFeatureQuality): string => {
	switch (quality) {
		case 'mundane':
			return '雜項'
		case 'common':
			return ''
		case 'uncommon':
			return '非常見'
		case 'rare':
			return '稀有'
		case 'veryRare':
			return '秘稀'
		case 'legendary':
			return '傳說'
	}
}

const CharacterFeatureCard = ({
	feature,
	initOverridedFeature,
	onChange,
}: Props): JSX.Element => {
	const [isInited, setIsInited] = useState<boolean>(false)
	const [activeFeature, setActiveFeature] = useState<CharacterFeature>(feature)
	const [selectValue, setSelectValue] = useState<
		CharacterFeature | CharacterFeature[] | undefined
	>(undefined)
	const [needToOnChange, setNeedToOnChange] = useState<boolean>(false)
	const featureOptions = useMemo(
		() =>
			(feature.options || []).map((o: Partial<CharacterFeature>) => ({
				...feature,
				...o,
				options: undefined,
			})),
		[feature]
	)

	const handleChangeFeatureOption = useCallback(
		(value: CharacterFeature | CharacterFeature[] | undefined) => {
			setSelectValue(value)
		},
		[setSelectValue]
	)

	useEffect(() => {
		if (selectValue) {
			if (Array.isArray(selectValue)) {
				if (selectValue.length === 0) {
					setActiveFeature({ ...feature, amount: 0 })
				} else {
					const newFeature: CharacterFeature = {
						...feature,
						code: selectValue.map((f) => f.code).join(';'),
						overridedCode: feature.code,
						name: `${feature.name} - ${selectValue
							.map((f) => f.name)
							.join('+')}`,
						description: selectValue.map((f) => f.description).join(''),
						mutations: ([] as CharacterMutation[]).concat(
							...selectValue.map((f) => f.mutations || [])
						),
						options: undefined,
						amount: selectValue.length,
					}
					setActiveFeature(newFeature)
				}
			} else {
				setActiveFeature({
					...selectValue,
					overridedCode: feature.code,
					name: `${feature.name}-${selectValue.name}`,
				})
			}

			setNeedToOnChange(true)
		}
	}, [selectValue, setActiveFeature])

	useEffect(() => {
		if (onChange && needToOnChange) {
			onChange(activeFeature)
			setNeedToOnChange(false)
		}
	}, [onChange, needToOnChange, activeFeature])

	const _foLabel = useCallback((value: CharacterFeature) => value.name, [])

	useEffect(() => {
		if (!isInited) {
			if (initOverridedFeature && featureOptions && featureOptions.length > 0) {
				const options = initOverridedFeature.code
					.split(';')
					.map((c) => featureOptions.find((fo) => fo.code === c))
					.filter((fo) => !!fo) as CharacterFeature[]

				if (options.length === 1) {
					setSelectValue(options[0])
				} else {
					setSelectValue(options)
				}
			}

			setIsInited(true)
		}
	}, [isInited, setIsInited, initOverridedFeature, featureOptions])

	const featureCardHeaderLeftContent = useMemo<JSX.Element>(() => {
		if (!isInited) {
			return <></>
		}

		if (featureOptions.length === 0) {
			return <div>{activeFeature.name}</div>
		}

		const _isMulti = feature.amount && feature.amount >= 2

		return (
			<>
				<FeatureOptionsSelect
					onChange={handleChangeFeatureOption}
					options={featureOptions}
					placeholder={`${feature.name} (請選擇 ${feature.amount || 1} 項)`}
					formatOptionLabel={_foLabel}
					isOptionSelected={() => false}
					searchable={false}
					clearable={false}
					isMulti={_isMulti}
					isOptionDisabled={(_: unknown, value: CharacterFeature[]) =>
						_isMulti && value.length >= (feature.amount || 0)
					}
					closeMenuOnSelect={!_isMulti}
					defaultValue={selectValue}
				></FeatureOptionsSelect>
			</>
		)
	}, [featureOptions, isInited])

	return (
		<FeatureCard
			className={classnames(
				activeFeature.type && `fc-type-${activeFeature.type}`,
				activeFeature.quality && `fc-quality-${activeFeature.quality}`
			)}
		>
			<FeatureCardHeader data-background-color>
				<FeatureCardHeaderLeft>
					{featureCardHeaderLeftContent}
				</FeatureCardHeaderLeft>
				<FeatureCardHeaderRight>
					{activeFeature.type && <div>{typeToString(activeFeature.type)}</div>}
					{activeFeature.cost && <div>{costToString(activeFeature.cost)}</div>}
					{activeFeature.charge && (
						<div>{chargeToString(activeFeature.charge)}</div>
					)}
					{activeFeature.quality && (
						<div>{qualityToString(activeFeature.quality)}</div>
					)}
				</FeatureCardHeaderRight>
			</FeatureCardHeader>
			<FeatureCardBody>{activeFeature.description}</FeatureCardBody>
		</FeatureCard>
	)
}

export default CharacterFeatureCard
