import React, { useMemo } from 'react'

import {
	CharacterFeature,
	CharacterFeatureQuality,
	CharacterFeatureType,
} from '../../types/character.type'

import CharacterFeatureCard from '../../components/Character/CharacterFeatureCard'
import Masonry from '../../components/Masonry/Masonry'

type Props = {
	features: CharacterFeature[] | undefined
	visibility?: (CharacterFeatureType | CharacterFeatureQuality)[]
	hidden?: (CharacterFeatureType | CharacterFeatureQuality)[]
	sorting?: (CharacterFeatureType | CharacterFeatureQuality)[]
	onFeatureChange?: (f: CharacterFeature) => void
	overridedFeaturesMap?: Record<string, CharacterFeature>
}

const CBFeatures = ({
	features,
	visibility,
	hidden,
	sorting,
	onFeatureChange,
	overridedFeaturesMap,
}: Props): JSX.Element => {
	const _features = useMemo(() => {
		let result = features || []
		if (visibility) {
			result = result.filter(
				(f) =>
					(f.type && visibility.indexOf(f.type) !== -1) ||
					(f.quality && visibility.indexOf(f.quality) !== -1)
			)
		}
		if (hidden) {
			result = result.filter(
				(f) =>
					(f.type && hidden.indexOf(f.type) === -1) ||
					(f.quality && hidden.indexOf(f.quality) === -1)
			)
		}
		if (sorting) {
			result.sort(
				(a, b) =>
					(!a.type && !a.quality
						? 999
						: Math.max(
								!!a.type ? sorting.indexOf(a.type) : 0,
								!!a.quality ? sorting.indexOf(a.quality) : 0
						  )) -
					(!b.type && !b.quality
						? 999
						: Math.max(
								!!b.type ? sorting.indexOf(b.type) : 0,
								!!b.quality ? sorting.indexOf(b.quality) : 0
						  ))
			)
		}

		return result
	}, [features, visibility, hidden, sorting])

	return (
		<>
			<Masonry
				breakpointCols={{
					default: 3,
					1200: 2,
					700: 1,
				}}
			>
				{(_features || []).map((feature) => (
					<CharacterFeatureCard
						key={feature.code}
						feature={feature}
						onChange={onFeatureChange}
						initOverridedFeature={overridedFeaturesMap?.[feature.code]}
					/>
				))}
			</Masonry>
		</>
	)
}

export default CBFeatures
