import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

import 'bootstrap-icons/font/bootstrap-icons.css'
import { BiIconName } from './BiIconName'

const StyledI = styled.i`
	margin-top: -2px;
`

type Props = React.HTMLAttributes<HTMLElement> & {
	icon: BiIconName
	fixWidth?: boolean
	size?: 'normal' | 'lg' | '2x' | '3x' | '4x' | '5x'
}

const BiIcon = ({
	icon,
	fixWidth = true,
	size = 'normal',
	className,
	...others
}: Props) => (
	<StyledI
		className={classnames(
			'bi',
			`bi-${icon}`,
			`bi-${size}`,
			{
				'bi-fw': fixWidth,
			},
			className
		)}
		{...others}
	/>
)

export default React.memo(BiIcon)
