import React from 'react'

import CharacterInstance from '../../instances/character.instance'
import { Background } from '../../types/background.type'
import { CharacterDetailAttachableOriginAndOverride } from '../../types/character.type'
import { Cls } from '../../types/cls.type'
import { Race } from '../../types/race.type'
import { Ruleset } from '../../types/ruleset.type'

export type Pointbuy = {
	strPointbuyBonus: number
	dexPointbuyBonus: number
	conPointbuyBonus: number
	intPointbuyBonus: number
	wisPointbuyBonus: number
	chaPointbuyBonus: number
}

export type Bio = {
	name: string
	nickname: string
	alignment: string
	appearance: string
	personalTraits: string
	ideals: string
	bonds: string
	flaws: string
}

export type CBContextProps = {
	initRulesetAttachableOAO?:
		| CharacterDetailAttachableOriginAndOverride
		| undefined
	initRaceAttachableOAO?: CharacterDetailAttachableOriginAndOverride | undefined
	initClsAttachableOAO?: CharacterDetailAttachableOriginAndOverride | undefined
	initBackgroundAttachableOAO?:
		| CharacterDetailAttachableOriginAndOverride
		| undefined
	initPointbuyAttachableOAO?:
		| CharacterDetailAttachableOriginAndOverride
		| undefined
	initBioAttachableOAO?: CharacterDetailAttachableOriginAndOverride | undefined

	ruleset?: Ruleset | undefined
	race?: Race | undefined
	cls?: Cls | undefined
	background?: Background | undefined
	pointbuy?: Pointbuy | undefined
	bio?: Bio | undefined
	rulesetAttachableOAO?: CharacterDetailAttachableOriginAndOverride | undefined
	raceAttachableOAO?: CharacterDetailAttachableOriginAndOverride | undefined
	clsAttachableOAO?: CharacterDetailAttachableOriginAndOverride | undefined
	backgroundAttachableOAO?:
		| CharacterDetailAttachableOriginAndOverride
		| undefined
	pointbuyAttachableOAO?: CharacterDetailAttachableOriginAndOverride | undefined
	bioAttachableOAO?: CharacterDetailAttachableOriginAndOverride | undefined
}

const CBContext = React.createContext<{
	context: CBContextProps
	setContext: (fn: (prev: CBContextProps) => CBContextProps) => void
	ci: CharacterInstance
}>({
	context: {},
	setContext: () => {
		//
	},
	ci: new CharacterInstance(),
})

export default CBContext
