import {
	CharacterProperty,
	CharacterPropertyDTO,
} from '../types/character.type'

const formatModifierToString = (n: number): string =>
	n >= 0 ? `+${n}` : n.toString()

export const transformPropertyToDTO = (
	p: CharacterProperty
): CharacterPropertyDTO => {
	return {
		bio: {
			name: (p['bio_name'] as string) || '',
			nickname: (p['bio_nickname'] as string) || '',
			alignment: (p['bio_alignment'] as string) || '',
			appearance: (p['bio_appearance'] as string) || '',
			personalTraits: (p['bio_personalTraits'] as string) || '',
			ideals: (p['bio_ideals'] as string) || '',
			bonds: (p['bio_bonds'] as string) || '',
			flaws: (p['bio_flaws'] as string) || '',
		},
		stat: {
			strength: {
				modifier: (p['stat_strength_modifier'] as number) || 0,
				modifierString: formatModifierToString(
					(p['stat_strength_modifier'] as number) || 0
				),
				score: (p['stat_strength_score'] as number) || 10,
			},
			dexterity: {
				modifier: (p['stat_dexterity_modifier'] as number) || 0,
				modifierString: formatModifierToString(
					(p['stat_dexterity_modifier'] as number) || 0
				),
				score: (p['stat_dexterity_score'] as number) || 10,
			},
			constitution: {
				modifier: (p['stat_constitution_modifier'] as number) || 0,
				modifierString: formatModifierToString(
					(p['stat_constitution_modifier'] as number) || 0
				),
				score: (p['stat_constitution_score'] as number) || 10,
			},
			intelligence: {
				modifier: (p['stat_intelligence_modifier'] as number) || 0,
				modifierString: formatModifierToString(
					(p['stat_intelligence_modifier'] as number) || 0
				),
				score: (p['stat_intelligence_score'] as number) || 10,
			},
			wisdom: {
				modifier: (p['stat_wisdom_modifier'] as number) || 0,
				modifierString: formatModifierToString(
					(p['stat_wisdom_modifier'] as number) || 0
				),
				score: (p['stat_wisdom_score'] as number) || 10,
			},
			charisma: {
				modifier: (p['stat_charisma_modifier'] as number) || 0,
				modifierString: formatModifierToString(
					(p['stat_charisma_modifier'] as number) || 0
				),
				score: (p['stat_charisma_score'] as number) || 10,
			},
		},
		level: (p['level'] as number) || 0,
		proficiencyBonus: (p['proficiencyBonus'] as number) || 0,
		hp: {
			max: (p['hp_max'] as number) || 0,
			current: (p['hp_current'] as number) || 0,
		},
		ac: (p['ac'] as number) || 0,
		skills: {
			athletics: {
				modifier:
					((p['skill_athletics_modifier'] as number) || 0) +
					((p['stat_strength_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_athletics_modifier'] as number) || 0) +
						((p['stat_strength_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_athletics_proficiencyRatio'] as number) || 0) >= 1,
				isMaster: ((p['skill_athletics_proficiencyRatio'] as number) || 0) >= 2,
			},
			acrobatics: {
				modifier:
					((p['skill_acrobatics_modifier'] as number) || 0) +
					((p['stat_dexterity_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_acrobatics_modifier'] as number) || 0) +
						((p['stat_dexterity_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_acrobatics_proficiencyRatio'] as number) || 0) >= 1,
				isMaster:
					((p['skill_acrobatics_proficiencyRatio'] as number) || 0) >= 2,
			},
			sleightOfHand: {
				modifier:
					((p['skill_sleightOfHand_modifier'] as number) || 0) +
					((p['stat_dexterity_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_sleightOfHand_modifier'] as number) || 0) +
						((p['stat_dexterity_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_sleightOfHand_proficiencyRatio'] as number) || 0) >= 1,
				isMaster:
					((p['skill_sleightOfHand_proficiencyRatio'] as number) || 0) >= 2,
			},
			stealth: {
				modifier:
					((p['skill_stealth_modifier'] as number) || 0) +
					((p['stat_dexterity_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_stealth_modifier'] as number) || 0) +
						((p['stat_dexterity_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_stealth_proficiencyRatio'] as number) || 0) >= 1,
				isMaster: ((p['skill_stealth_proficiencyRatio'] as number) || 0) >= 2,
			},
			arcane: {
				modifier:
					((p['skill_arcane_modifier'] as number) || 0) +
					((p['stat_intelligence_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_arcane_modifier'] as number) || 0) +
						((p['stat_intelligence_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_arcane_proficiencyRatio'] as number) || 0) >= 1,
				isMaster: ((p['skill_arcane_proficiencyRatio'] as number) || 0) >= 2,
			},
			history: {
				modifier:
					((p['skill_history_modifier'] as number) || 0) +
					((p['stat_intelligence_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_history_modifier'] as number) || 0) +
						((p['stat_intelligence_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_history_proficiencyRatio'] as number) || 0) >= 1,
				isMaster: ((p['skill_history_proficiencyRatio'] as number) || 0) >= 2,
			},
			investigation: {
				modifier:
					((p['skill_investigation_modifier'] as number) || 0) +
					((p['stat_intelligence_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_investigation_modifier'] as number) || 0) +
						((p['stat_intelligence_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_investigation_proficiencyRatio'] as number) || 0) >= 1,
				isMaster:
					((p['skill_investigation_proficiencyRatio'] as number) || 0) >= 2,
			},
			nature: {
				modifier:
					((p['skill_nature_modifier'] as number) || 0) +
					((p['stat_intelligence_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_nature_modifier'] as number) || 0) +
						((p['stat_intelligence_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_nature_proficiencyRatio'] as number) || 0) >= 1,
				isMaster: ((p['skill_nature_proficiencyRatio'] as number) || 0) >= 2,
			},
			religion: {
				modifier:
					((p['skill_religion_modifier'] as number) || 0) +
					((p['stat_intelligence_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_religion_modifier'] as number) || 0) +
						((p['stat_intelligence_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_religion_proficiencyRatio'] as number) || 0) >= 1,
				isMaster: ((p['skill_religion_proficiencyRatio'] as number) || 0) >= 2,
			},
			animalHandling: {
				modifier:
					((p['skill_animalHandling_modifier'] as number) || 0) +
					((p['stat_wisdom_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_animalHandling_modifier'] as number) || 0) +
						((p['stat_wisdom_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_animalHandling_proficiencyRatio'] as number) || 0) >= 1,
				isMaster:
					((p['skill_animalHandling_proficiencyRatio'] as number) || 0) >= 2,
			},
			insight: {
				modifier:
					((p['skill_insight_modifier'] as number) || 0) +
					((p['stat_wisdom_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_insight_modifier'] as number) || 0) +
						((p['stat_wisdom_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_insight_proficiencyRatio'] as number) || 0) >= 1,
				isMaster: ((p['skill_insight_proficiencyRatio'] as number) || 0) >= 2,
			},
			medicine: {
				modifier:
					((p['skill_medicine_modifier'] as number) || 0) +
					((p['stat_wisdom_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_medicine_modifier'] as number) || 0) +
						((p['stat_wisdom_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_medicine_proficiencyRatio'] as number) || 0) >= 1,
				isMaster: ((p['skill_medicine_proficiencyRatio'] as number) || 0) >= 2,
			},
			perception: {
				modifier:
					((p['skill_perception_modifier'] as number) || 0) +
					((p['stat_wisdom_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_perception_modifier'] as number) || 0) +
						((p['stat_wisdom_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_perception_proficiencyRatio'] as number) || 0) >= 1,
				isMaster:
					((p['skill_perception_proficiencyRatio'] as number) || 0) >= 2,
			},
			survival: {
				modifier:
					((p['skill_survival_modifier'] as number) || 0) +
					((p['stat_wisdom_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_survival_modifier'] as number) || 0) +
						((p['stat_wisdom_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_survival_proficiencyRatio'] as number) || 0) >= 1,
				isMaster: ((p['skill_survival_proficiencyRatio'] as number) || 0) >= 2,
			},
			deception: {
				modifier:
					((p['skill_deception_modifier'] as number) || 0) +
					((p['stat_charisma_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_deception_modifier'] as number) || 0) +
						((p['stat_charisma_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_deception_proficiencyRatio'] as number) || 0) >= 1,
				isMaster: ((p['skill_deception_proficiencyRatio'] as number) || 0) >= 2,
			},
			intimidation: {
				modifier:
					((p['skill_intimidation_modifier'] as number) || 0) +
					((p['stat_charisma_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_intimidation_modifier'] as number) || 0) +
						((p['stat_charisma_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_intimidation_proficiencyRatio'] as number) || 0) >= 1,
				isMaster:
					((p['skill_intimidation_proficiencyRatio'] as number) || 0) >= 2,
			},
			performance: {
				modifier:
					((p['skill_performance_modifier'] as number) || 0) +
					((p['stat_charisma_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_performance_modifier'] as number) || 0) +
						((p['stat_charisma_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_performance_proficiencyRatio'] as number) || 0) >= 1,
				isMaster:
					((p['skill_performance_proficiencyRatio'] as number) || 0) >= 2,
			},
			persuasion: {
				modifier:
					((p['skill_persuasion_modifier'] as number) || 0) +
					((p['stat_charisma_modifier'] as number) || 0),
				modifierString: formatModifierToString(
					((p['skill_persuasion_modifier'] as number) || 0) +
						((p['stat_charisma_modifier'] as number) || 0)
				),
				isProficient:
					((p['skill_persuasion_proficiencyRatio'] as number) || 0) >= 1,
				isMaster:
					((p['skill_persuasion_proficiencyRatio'] as number) || 0) >= 2,
			},
		},
		proficiency: {
			languages: (p['proficiency_languages'] as string[]) || [],
			tools: (p['proficiency_tools'] as string[]) || [],
			weapons: (p['proficiency_weapons'] as string[]) || [],
			armors: (p['proficiency_armors'] as string[]) || [],
		},
	}
}
