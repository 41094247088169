import React from 'react'
import { useQuery } from 'react-query'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { Character } from '../types/character.type'

import { getMyCharacters } from '../helpers/api.helper'

import { Alert, Button, Card, Col, Row } from 'reactstrap'
import PageBrandname from '../components/Page/PageBrand'
import PageTitle from '../components/Page/PageTitle'

const StyledRow = styled(Row)`
	min-height: 200px;
	align-items: stretch;
`

const StyledCol = styled(Col)`
	margin-bottom: 29px;
`

const CharacterCardBase = styled(Card)`
	margin-bottom: 0;
	height: 100px;
`

const CharacterCard = ({
	character,
}: {
	character: Character
}): JSX.Element => {
	return <CharacterCardBase body>{character.name}</CharacterCardBase>
}

const CharacterEmptyCard = styled(CharacterCardBase)`
	background-color: rgba(0, 0, 0, 0.05);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	padding: 2em 1em;
`

const CharactersSection = (): JSX.Element => {
	const routeMatch = useRouteMatch()
	const characterQuery = useQuery(
		['characters', 'me'],
		() => getMyCharacters(),
		{
			staleTime: 60000,
			select: (data: Character[]) => {
				return [...data, null, null, null]
					.splice(0, Math.max(3, data.length))
					.map((characterOrNull, index) => ({
						_key: index,
						character: characterOrNull,
					}))
			},
		}
	)

	if (characterQuery.isLoading || !characterQuery.data) {
		return <div>Loading...</div>
	}

	if (characterQuery.isError) {
		return <Alert color='danger'>Error</Alert>
	}

	return (
		<>
			<StyledRow>
				{characterQuery.data.map(
					({
						_key,
						character,
					}: {
						_key: number
						character: Character | null
					}) => (
						<StyledCol md={12} lg={4} key={_key}>
							{character ? (
								<Link to={`${routeMatch.url}/${character.id}`}>
									<CharacterCard character={character} />
								</Link>
							) : (
								<CharacterEmptyCard>空位</CharacterEmptyCard>
							)}
						</StyledCol>
					)
				)}
			</StyledRow>
		</>
	)
}

const Characters = (): JSX.Element => {
	const routeMatch = useRouteMatch()

	return (
		<>
			<PageTitle>我的角色</PageTitle>
			<PageBrandname>我的角色</PageBrandname>
			<div className='content'>
				<div style={{ textAlign: 'right' }}>
					<Link to={`${routeMatch.url}/new/builder`}>
						<Button color='primary'>創建一個新角色</Button>
					</Link>
				</div>

				<CharactersSection />
			</div>
		</>
	)
}

export default Characters
