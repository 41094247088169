import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import {
	CharacterAttachable,
	CharacterFeature,
} from '../../types/character.type'
import { Race } from '../../types/race.type'

import { getRaceAttachable, getRaces } from '../../helpers/api.helper'

import { Alert, Button } from 'reactstrap'
import StyledReactSelect from '../../components/Select/StyledReactSelect'

import CBContext from './CBContext'
import CBFeatures from './CBFeatures'

const CBRaces = (): JSX.Element => {
	const { context } = useContext(CBContext)
	const [selectedItem, setSelectedItem] = useState<Race | null>(null)

	const _selectGetOptionLabel = useCallback((item: Race) => item.name, [])
	const _selectIsOptionSelected = useCallback(
		(item: Race) => item.id === selectedItem?.id,
		[selectedItem]
	)

	const racesQuery = useQuery<Race[]>(['races'], () => getRaces())

	useEffect(() => {
		if (context.initRaceAttachableOAO && racesQuery.data) {
			const found = racesQuery.data.find(
				(item) => item.id === context.initRaceAttachableOAO?.origin?.race
			)
			if (found) {
				setSelectedItem(found)
			}
		}
	}, [context.initRaceAttachableOAO, racesQuery.data])

	if (racesQuery.isLoading) {
		return <div>Loading...</div>
	}

	if (racesQuery.isError) {
		return <div>Error</div>
	}

	return (
		<>
			{/* <Row>
				{query.data?.map((item) => (
					<Col xs={12} sm={6} md={4} lg={2} key={item.id}>
						{item.name}
					</Col>
				))}
			</Row> */}

			<StyledReactSelect
				options={racesQuery.data}
				getOptionLabel={_selectGetOptionLabel}
				isOptionSelected={_selectIsOptionSelected}
				onChange={setSelectedItem}
				value={selectedItem}
			/>

			<div>{selectedItem && <CBRaceDetail race={selectedItem} />}</div>
		</>
	)
}

const StyledCBRaceDetailWrapper = styled.div`
	padding: 1em;
	margin-top: 1em;
	border-radius: 12px;
	background-color: rgba(82, 77, 47, 0.08);
`

const CBRaceDetail = ({ race }: { race: Race }) => {
	const { context, setContext } = useContext(CBContext)
	const [isInited, setInited] = useState<boolean>(false)
	const [doSubmitOnce, setDoSubmitOnce] = useState<boolean>(false)
	const [overridedFeaturesMap, setOverridedFeaturesMap] = useState<
		Record<string, CharacterFeature>
	>({})
	const [error, setError] = useState<string>('')

	const attachableQuery = useQuery<CharacterAttachable>(
		['race', race.id, 'attachable'],
		() => getRaceAttachable(race.id),
		{
			enabled: !!race,
		}
	)

	const handleFeatureChange = useCallback(
		(f: CharacterFeature) => {
			setOverridedFeaturesMap((prev) => ({
				...prev,
				[f.overridedCode || f.code]: f,
			}))
		},
		[setOverridedFeaturesMap]
	)

	const handleSubmit = useCallback(() => {
		for (const f of attachableQuery.data?.features || []) {
			if (
				f.options &&
				f.options.length > 0 &&
				(!overridedFeaturesMap[f.code] ||
					overridedFeaturesMap[f.code].amount !== f.amount)
			) {
				setError(`你要揀選 ${f.name} 中的其中 ${f.amount || 1}個選項。`)
				return
			}
		}

		setContext((prev) => ({
			...prev,
			race,
			raceAttachableOAO: {
				origin: attachableQuery.data,
				override: { features: Object.values(overridedFeaturesMap) },
			},
		}))
		setError('')
	}, [setContext, setError, race, attachableQuery.data, overridedFeaturesMap])

	useEffect(() => {
		if (context.initRaceAttachableOAO) {
			const { override } = context.initRaceAttachableOAO
			const newOverridedFeaturesMap: Record<string, CharacterFeature> = {}
			for (const item of override?.features || []) {
				newOverridedFeaturesMap[item.overridedCode || item.code] = item
			}

			setOverridedFeaturesMap(newOverridedFeaturesMap)
			setDoSubmitOnce(true)
		}

		setInited(true)
	}, [context.initRaceAttachableOAO])

	useEffect(() => {
		if (doSubmitOnce && attachableQuery.data && isInited) {
			handleSubmit()
			setDoSubmitOnce(false)
		}
	}, [doSubmitOnce, attachableQuery.data, isInited])

	if (attachableQuery.isLoading || !isInited) {
		return <StyledCBRaceDetailWrapper>Loading...</StyledCBRaceDetailWrapper>
	}

	if (attachableQuery.isError) {
		return <StyledCBRaceDetailWrapper>Error</StyledCBRaceDetailWrapper>
	}

	return (
		<StyledCBRaceDetailWrapper>
			<div className='text-center'>
				<Button size='lg' color='primary' onClick={handleSubmit}>
					選擇此種族
				</Button>
			</div>
			<Alert isOpen={!!error} color='danger'>
				{error}
			</Alert>
			<p>
				選擇 <strong>{race.name}</strong> 作為種族的話，角色會得到以下特性:
			</p>
			<CBFeatures
				features={attachableQuery.data?.features}
				hidden={['metadata']}
				sorting={['improvement', 'static', 'triggered', 'activated', 'mundane']}
				onFeatureChange={handleFeatureChange}
				overridedFeaturesMap={overridedFeaturesMap}
			/>
		</StyledCBRaceDetailWrapper>
	)
}

export default CBRaces
