/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import lightFormat from 'date-fns/lightFormat'
import classnames from 'classnames'

import {
	Character,
	CharacterFeatureQuality,
	CharacterFeatureType,
	CharacterLog,
} from '../types/character.type'

import {
	getCharacterById,
	getCharacterLogsByCharacterId,
} from '../helpers/api.helper'
import { transformPropertyToDTO } from '../helpers/character.helper'

// reactstrap components
import {
	Alert,
	Card,
	CardBody,
	CardHeader,
	Col,
	Nav,
	NavItem,
	NavLink,
	Row,
	Spinner,
	Table,
} from 'reactstrap'
import Button from '../components/Button'
import CharacterFeatureCard from '../components/Character/CharacterFeatureCard'
import CharacterLevelAndXpProgress from '../components/Character/CharacterLevelAndXpProgress'
import CMI, { CMICard } from '../components/Character/CharacterModItem'
import Masonry from '../components/Masonry/Masonry'
import PageBrandname from '../components/Page/PageBrand'
import PageTitle from '../components/Page/PageTitle'

const coverPlaceholder = '/assets/images/cover-placeholder.jpg'
const portraitPlaceholder = '/assets/images/portrait-placeholder.png'

enum CharacterTabEnum {
	Logs,
	Features,
	SkillsAndProficiencies,
	Spells,
	Others,
}
const CharacterTabs = [
	{ value: CharacterTabEnum.Logs, label: '活動紀錄' },
	{ value: CharacterTabEnum.Features, label: '特性' },
	{ value: CharacterTabEnum.SkillsAndProficiencies, label: '技能&熟練' },
	{ value: CharacterTabEnum.Spells, label: '法術' },
	{ value: CharacterTabEnum.Others, label: '其他' },
]

const NavContainer = styled.div`
	margin-top: 16px;

	> * {
		display: none;

		&.show {
			display: block;
		}
	}
`

const CharacterLogDiv = styled.div`
	padding: 1em;
	border-bottom: 1px solid #aaaaaa;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.cLog-right {
		display: flex;
		font-weight: bold;

		> * {
			margin-left: 1em;
		}
	}

	.cLog-helper-text {
		display: flex;
		color: #aaaaaa;
		font-size: 12px;

		> * {
			margin-right: 1em;
		}
	}

	.cLog-xp {
		color: #09b025;
		width: 80px;
		text-align: right;
	}

	.cLog-gp {
		color: #ebb73f;
		width: 80px;
		text-align: right;
	}
`

const formatNumberToSignedNumberString = (n: number) =>
	n >= 0 ? `+${n}` : `${n}`

const CharacterDetail = (): JSX.Element => {
	const routeMatch = useRouteMatch()
	const { id } = useParams<{ id: string }>()
	const [activeTab, setActiveTab] = useState<CharacterTabEnum>(
		CharacterTabEnum.Logs
	)
	const [featuresConfig] = useState<{
		visibility?: (CharacterFeatureType | CharacterFeatureQuality)[]
		hidden?: (CharacterFeatureType | CharacterFeatureQuality)[]
		sorting?: (CharacterFeatureType | CharacterFeatureQuality)[]
	}>({
		hidden: ['improvement', 'metadata', 'mundane'],
		sorting: ['activated', 'triggered', 'static'],
	})

	const characterQuery = useQuery<Character>(['character', id], () =>
		getCharacterById(id)
	)

	const cp = useMemo(
		() =>
			characterQuery.data
				? transformPropertyToDTO(characterQuery.data.property)
				: undefined,
		[characterQuery.data]
	)

	const cmiSkills = useMemo(
		() => [
			{
				label: '運動(力量)',
				subLabel: 'Athletics',
				value: cp?.skills.athletics,
			},
			{
				label: '體操(敏捷)',
				subLabel: 'Acrobatics',
				value: cp?.skills.acrobatics,
			},
			{
				label: '巧手(敏捷)',
				subLabel: 'Sleight of Hand',
				value: cp?.skills.sleightOfHand,
			},
			{ label: '隱匿(敏捷)', subLabel: 'Stealth', value: cp?.skills.stealth },
			{ label: '奧術(智力)', subLabel: 'Arcane', value: cp?.skills.arcane },
			{ label: '歷史(智力)', subLabel: 'History', value: cp?.skills.history },
			{
				label: '調查(智力)',
				subLabel: 'Investigation',
				value: cp?.skills.investigation,
			},
			{ label: '自然(智力)', subLabel: 'Nature', value: cp?.skills.nature },
			{ label: '宗教(智力)', subLabel: 'Religion', value: cp?.skills.religion },
			{
				label: '馴獸(智慧)',
				subLabel: 'Animal Handling',
				value: cp?.skills.animalHandling,
			},
			{ label: '洞悉(智慧)', subLabel: 'Insight', value: cp?.skills.insight },
			{ label: '醫學(智慧)', subLabel: 'Medicine', value: cp?.skills.medicine },
			{
				label: '觀察(智慧)',
				subLabel: 'Perception',
				value: cp?.skills.perception,
			},
			{ label: '求生(智慧)', subLabel: 'Survival', value: cp?.skills.survival },
			{
				label: '欺暪(魅力)',
				subLabel: 'Deception',
				value: cp?.skills.deception,
			},
			{
				label: '威嚇(魅力)',
				subLabel: 'Intimidation',
				value: cp?.skills.intimidation,
			},
			{
				label: '表演(魅力)',
				subLabel: 'Performance',
				value: cp?.skills.performance,
			},
			{
				label: '遊說(魅力)',
				subLabel: 'Persuasion',
				value: cp?.skills.persuasion,
			},
		],
		[cp?.skills]
	)

	const characterLogsQuery = useQuery<CharacterLog[]>(
		['character', id, 'logs'],
		() => getCharacterLogsByCharacterId(id),
		{
			select: (cLogs) =>
				cLogs.map(({ worldStartAt, worldEndAt, ...others }) => ({
					worldStartAt: worldStartAt
						? lightFormat(new Date(worldStartAt), '第三紀元 yyyy年MM月dd日')
						: '',
					worldEndAt: worldEndAt
						? lightFormat(new Date(worldEndAt), '第三紀元 yyyy年MM月dd日')
						: '',
					...others,
				})),
		}
	)

	const character = useMemo<Character | undefined>(() => characterQuery.data, [
		characterQuery.data,
	])

	const features = useMemo(() => {
		let result = character?.features || []

		const { visibility, hidden, sorting } = featuresConfig
		if (visibility) {
			result = result.filter(
				(f) =>
					(f.type && visibility.indexOf(f.type) !== -1) ||
					(f.quality && visibility.indexOf(f.quality) !== -1)
			)
		}
		if (hidden) {
			result = result.filter(
				(f) =>
					(!f.type && !f.quality) ||
					((f.type ? hidden.indexOf(f.type) === -1 : true) &&
						(f.quality ? hidden.indexOf(f.quality) === -1 : true))
			)
		}
		if (sorting) {
			result.sort(
				(a, b) =>
					(!a.type && !a.quality
						? 999
						: Math.max(
								!!a.type ? sorting.indexOf(a.type) : 0,
								!!a.quality ? sorting.indexOf(a.quality) : 0
						  )) -
					(!b.type && !b.quality
						? 999
						: Math.max(
								!!b.type ? sorting.indexOf(b.type) : 0,
								!!b.quality ? sorting.indexOf(b.quality) : 0
						  ))
			)
		}

		return result
	}, [character, featuresConfig])

	if (characterQuery.isLoading || !character) {
		return (
			<div className='content'>
				<Spinner type='grow' color='primary' />
			</div>
		)
	}

	if (characterQuery.isError) {
		return (
			<div className='content'>
				<Alert color='danger'>讀取角色失敗。</Alert>
			</div>
		)
	}

	return (
		<>
			<PageTitle>{character.name}</PageTitle>
			<PageBrandname>{character.name}</PageBrandname>
			<div className='content'>
				<Row>
					<Col md='4'>
						<Card className='card-user'>
							<div className='image'>
								<img
									alt='...'
									src={character.coverImage?.url || coverPlaceholder}
								/>
							</div>
							<CardBody>
								<div className='author'>
									<a href='#pablo' onClick={(e) => e.preventDefault()}>
										<img
											alt='...'
											className='avatar border-gray'
											src={character.portrait?.url || portraitPlaceholder}
										/>
										<div className='text-muted'>{character.code}</div>
										<h5 className='title'>{character.name}</h5>
									</a>
									<p className='description'>@{character.player?.username}</p>
								</div>
								<p className='description text-center'>{character.motto}</p>

								<div style={{ marginBottom: '1em' }}>
									<CharacterLevelAndXpProgress xp={character.xp} />
								</div>

								<Table striped>
									<tbody>
										<tr>
											<th>城市</th>
											<td>{character.city?.name}</td>
										</tr>
										<tr>
											<th>種族</th>
											<td>{character.race?.name}</td>
										</tr>
										<tr>
											<th>職業</th>
											<td>
												{character.clses && character.clses.length > 0
													? character.clses[0].name
													: ''}
											</td>
										</tr>
										<tr>
											<th>背景</th>
											<td>{character.background?.name}</td>
										</tr>
									</tbody>
								</Table>
							</CardBody>
						</Card>
						<div className='text-center'>
							<Link to={`${routeMatch.url}/builder`}>
								<Button color='muted'>編緝角色</Button>
							</Link>
						</div>
					</Col>
					<Col md='8'>
						<Nav tabs>
							{CharacterTabs.map((tab) => (
								<NavItem key={tab.value}>
									<NavLink
										className={classnames({
											active: activeTab === tab.value,
										})}
										onClick={() => {
											setActiveTab(tab.value)
										}}
									>
										{tab.label}
									</NavLink>
								</NavItem>
							))}
						</Nav>

						<NavContainer>
							<div
								className={classnames({
									show: activeTab === CharacterTabEnum.Logs,
								})}
							>
								{characterLogsQuery.data &&
									characterLogsQuery.data.map((cLog) => {
										return (
											<CharacterLogDiv>
												<div>
													<div className='cLog-description'>
														{cLog.description}
													</div>
													<div className='cLog-helper-text'>
														{cLog.worldStartAt && (
															<div className='cLog-date'>
																{cLog.worldStartAt}
															</div>
														)}
														{cLog.game && (
															<div className='cLog-game'>
																[{cLog.game.code}] {cLog.game.title}
															</div>
														)}
													</div>
												</div>
												<div className='cLog-right'>
													{cLog.xp && (
														<div className='cLog-xp'>
															{formatNumberToSignedNumberString(cLog.xp)} XP
														</div>
													)}
													{cLog.gp && (
														<div className='cLog-gp'>
															{formatNumberToSignedNumberString(cLog.gp)} GP
														</div>
													)}
												</div>
											</CharacterLogDiv>
										)
									})}
							</div>

							<div
								className={classnames({
									show: activeTab === CharacterTabEnum.Features,
								})}
							>
								<Masonry
									breakpointCols={{
										default: 2,
										1200: 1,
									}}
								>
									{features.map((feature) => (
										<CharacterFeatureCard
											key={feature.code}
											feature={feature}
										/>
									))}
								</Masonry>
							</div>

							<div
								className={classnames({
									show: activeTab === CharacterTabEnum.SkillsAndProficiencies,
								})}
							>
								<CMICard>
									<CardHeader>
										<CMI label='能力值 Ability' />
									</CardHeader>
									<CardBody>
										<Row>
											<Col xs={4} sm={2} md={2} lg={2}>
												<div>力量</div>
												<div>
													{cp?.stat.strength.score} (
													{cp?.stat.strength.modifierString})
												</div>
											</Col>
											<Col xs={4} sm={2} md={2} lg={2}>
												<div>敏捷</div>
												<div>
													{cp?.stat.dexterity.score} (
													{cp?.stat.dexterity.modifierString})
												</div>
											</Col>
											<Col xs={4} sm={2} md={2} lg={2}>
												<div>體質</div>
												<div>
													{cp?.stat.constitution.score} (
													{cp?.stat.constitution.modifierString})
												</div>
											</Col>
											<Col xs={4} sm={2} md={2} lg={2}>
												<div>智力</div>
												<div>
													{cp?.stat.intelligence.score} (
													{cp?.stat.intelligence.modifierString})
												</div>
											</Col>
											<Col xs={4} sm={2} md={2} lg={2}>
												<div>感知</div>
												<div>
													{cp?.stat.wisdom.score} (
													{cp?.stat.wisdom.modifierString})
												</div>
											</Col>
											<Col xs={4} sm={2} md={2} lg={2}>
												<div>魅力</div>
												<div>
													{cp?.stat.charisma.score} (
													{cp?.stat.charisma.modifierString})
												</div>
											</Col>
										</Row>
									</CardBody>
								</CMICard>

								<Row>
									<Col xs={12} sm={4} lg={8}>
										<CMICard>
											<CardHeader>
												<CMI
													label='技能 Skills'
													value={`(熟練加值: +${cp?.proficiencyBonus})`}
												/>
											</CardHeader>
											<CardBody>
												<Row>
													{cmiSkills.map(({ label, subLabel, value }) => (
														<Col xs={12} sm={12} md={12} lg={6} key={label}>
															<CMI
																label={label}
																subLabel={subLabel}
																value={value?.modifierString}
															/>
														</Col>
													))}
												</Row>
											</CardBody>
										</CMICard>
									</Col>
									<Col xs={12} sm={4}>
										<CMICard>
											<CardHeader>語言 Languages</CardHeader>
											<CardBody>
												{cp?.proficiency.languages.map((item) => (
													<CMI key={item} label={item} display='pill' />
												))}
											</CardBody>
										</CMICard>

										<CMICard>
											<CardHeader>工具 Tools</CardHeader>
											<CardBody>
												{cp?.proficiency.tools.map((item) => (
													<CMI key={item} label={item} display='pill' />
												))}
											</CardBody>
										</CMICard>

										<CMICard>
											<CardHeader>武器、防具 Weapons, Armors</CardHeader>
											<CardBody>
												{cp?.proficiency.weapons.map((item) => (
													<CMI key={item} label={item} display='pill' />
												))}
												{cp?.proficiency.armors.map((item) => (
													<CMI key={item} label={item} display='pill' />
												))}
											</CardBody>
										</CMICard>
									</Col>
								</Row>
							</div>

							<div
								className={classnames({
									show: activeTab === CharacterTabEnum.Spells,
								})}
							>
								<Alert color='warning'>
									法術部分仍在開發中，誰叫製作者不是法師型玩家呢？
								</Alert>
							</div>
						</NavContainer>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default CharacterDetail
