import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useForm } from 'react-hook-form'

import {
	Button,
	Form,
	FormFeedback,
	FormGroup,
	FormText,
	Input,
	Label,
} from 'reactstrap'

import CBContext, { Bio } from './CBContext'

const _defaultFormValues = {
	name: '',
	nickname: '',
	alignment: 'nn',
	appearance: '',
	personalTraits: '',
	ideals: '',
	bonds: '',
	flaws: '',
}

const CBBio = (): JSX.Element => {
	const { context, setContext } = useContext(CBContext)
	const [isInited, setIsInited] = useState<boolean>(false)
	const [doSubmitOnce, setDoSubmitOnce] = useState<boolean>(false)

	const {
		register,
		handleSubmit: rhfHandleSubmit,
		getValues,
		formState,
		reset,
	} = useForm<Bio>({
		defaultValues: _defaultFormValues,
	})
	const { errors } = formState

	const onSubmit = useCallback(
		(bio: Bio) => {
			setContext((prev) => ({
				...prev,
				bio: getValues(),
				bioAttachableOAO: {
					override: {
						name: '自定義-角色設定',
						code: 'userdefined:bio',
						category: 'bio',
						features: [
							{
								name: '自定義-角色設定-角色設定',
								code: 'userdefined:bio:bio',
								type: 'metadata',
								mutations: [
									{
										key: 'bio_name',
										formula: `"${bio?.name}"`,
									},
									{
										key: 'bio_nickname',
										formula: `"${bio?.nickname}"`,
									},
									{
										key: 'bio_appearance',
										formula: `"${bio?.appearance}"`,
									},
									{
										key: 'bio_alignment',
										formula: `"${bio?.alignment}"`,
									},
									{
										key: 'bio_personalTraits',
										formula: `"${bio?.personalTraits}"`,
									},
									{
										key: 'bio_ideals',
										formula: `"${bio?.ideals}"`,
									},
									{
										key: 'bio_bonds',
										formula: `"${bio?.bonds}"`,
									},
									{
										key: 'bio_flaws',
										formula: `"${bio?.flaws}"`,
									},
								],
							},
						],
					},
				},
			}))
		},
		[setContext]
	)

	const handleSubmit = useCallback(rhfHandleSubmit(onSubmit), [
		rhfHandleSubmit,
		onSubmit,
	])

	useEffect(() => {
		if (context.initBioAttachableOAO) {
			const features = context.initBioAttachableOAO?.override?.features || []
			if (features.length > 0) {
				const newValue = { ..._defaultFormValues }
				const { mutations } = features[0]

				for (const m of mutations || []) {
					const value = m.formula.substr(1, m.formula.length - 2)
					switch (m.key) {
						case 'bio_name':
							newValue['name'] = value
							break
						case 'bio_nickname':
							newValue['nickname'] = value
							break
						case 'bio_appearance':
							newValue['appearance'] = value
							break
						case 'bio_alignment':
							newValue['alignment'] = value
							break
						case 'bio_personalTraits':
							newValue['personalTraits'] = value
							break
						case 'bio_ideals':
							newValue['ideals'] = value
							break
						case 'bio_bonds':
							newValue['bonds'] = value
							break
						case 'bio_flaws':
							newValue['flaws'] = value
							break
					}
				}

				reset(newValue)
				setDoSubmitOnce(true)
			}
		}

		setIsInited(true)
	}, [context.initBioAttachableOAO])

	useEffect(() => {
		if (handleSubmit && doSubmitOnce && isInited) {
			handleSubmit()
		}
	}, [handleSubmit, doSubmitOnce, isInited])

	if (!isInited) {
		return <div className='context'>Loading...</div>
	}

	return (
		<>
			<Form>
				<div className='text-center'>
					<Button
						type='submit'
						size='lg'
						color='primary'
						onClick={handleSubmit}
					>
						儲存
					</Button>
				</div>

				<FormGroup>
					<Label>名字(全名)</Label>
					<Input
						name='name'
						type='text'
						innerRef={register({
							required: '必須填寫',
						})}
						invalid={!!errors.name}
						autoComplete='off'
					/>
					{errors.name && <FormFeedback>{errors.name?.message}</FormFeedback>}
				</FormGroup>

				<FormGroup>
					<Label>名字(暱稱)</Label>
					<Input
						name='nickname'
						type='text'
						innerRef={register({
							required: '必須填寫',
						})}
						invalid={!!errors.nickname}
						autoComplete='off'
					/>
					{errors.nickname && (
						<FormFeedback>{errors.nickname?.message}</FormFeedback>
					)}
					<FormText color='muted'>
						是指同輩通常會叫的名字，一般常見於全名太長而取當中順口的兩三個字，或是取角色其中一項最鮮明的特徵來命名。
					</FormText>
				</FormGroup>

				<FormGroup>
					<Label>陣營</Label>
					<Input
						name='alignment'
						type='select'
						innerRef={register({
							required: '必須填寫',
						})}
						invalid={!!errors.alignment}
						autoComplete='off'
					>
						<option value='lg'>守序善良</option>
						<option value='ln'>守序中立</option>
						<option value='ng'>中立善良</option>
						<option value='nn'>中立</option>
						<option value='cg'>混亂善良</option>
						<option value='cn'>混亂中立</option>
					</Input>
					{errors.alignment && (
						<FormFeedback>{errors.alignment?.message}</FormFeedback>
					)}
					<FormText color='muted'>
						陣營是指角色的行動準則。
						<br />
						守序者會以組織利益為優先(對一般人而言組織是指社會，對冒險者則可能是其雇主或所屬公會)；混亂者則以個人或特定某人利益為優先。
						<br />
						善良者會顧慮他人感受；邪惡者則不介意甚至樂於踐踏他人感受。
						<br />
						<strong>禁止邪惡角色。</strong>
					</FormText>
				</FormGroup>

				<FormGroup>
					<Label>外觀</Label>
					<Input
						name='appearance'
						type='textarea'
						innerRef={register({
							required: '必須填寫',
						})}
						invalid={!!errors.appearance}
						autoComplete='off'
						rows={4}
					/>
					{errors.appearance && (
						<FormFeedback>{errors.appearance?.message}</FormFeedback>
					)}
					<FormText color='muted'>
						形容角色的外觀，例如性別、種族、膚色、髮色、臉型、裝束、神態、口頭禪、個性等等。
					</FormText>
				</FormGroup>

				<FormGroup>
					<Label>個人特質</Label>
					<Input
						name='personalTraits'
						type='text'
						innerRef={register({
							required: '必須填寫',
						})}
						invalid={!!errors.personalTraits}
						autoComplete='off'
					/>
					{errors.personalTraits && (
						<FormFeedback>{errors.personalTraits?.message}</FormFeedback>
					)}
					<FormText color='muted'>
						一到兩項能區別角色與其他人的特質。
						<br />
						例如: 他喜歡到酒館聽英雄故事、喜歡看冒險小說
					</FormText>
				</FormGroup>

				<FormGroup>
					<Label>理念</Label>
					<Input
						name='ideals'
						type='text'
						innerRef={register({
							required: '必須填寫',
						})}
						invalid={!!errors.ideals}
						autoComplete='off'
					/>
					{errors.ideals && (
						<FormFeedback>{errors.ideals?.message}</FormFeedback>
					)}
					<FormText color='muted'>
						一項角色所相信，且以此為生活目標的想法、名言、人物等。
						<br />
						例如: 一切的辛苦都是為了光明的未來
					</FormText>
				</FormGroup>

				<FormGroup>
					<Label>連繫</Label>
					<Input
						name='bonds'
						type='text'
						innerRef={register({
							required: '必須填寫',
						})}
						invalid={!!errors.bonds}
						autoComplete='off'
					/>
					{errors.bonds && <FormFeedback>{errors.bonds?.message}</FormFeedback>}
					<FormText color='muted'>
						一項角色與這個世界的連繫，而這是角色存在與世的證明。
						<br />
						例如: 他的父親是退休的將軍，而他的目標則是超越父親
					</FormText>
				</FormGroup>

				<FormGroup>
					<Label>缺憾</Label>
					<Input
						name='flaws'
						type='text'
						innerRef={register({
							required: '必須填寫',
						})}
						invalid={!!errors.flaws}
						autoComplete='off'
					/>
					{errors.flaws && <FormFeedback>{errors.flaws?.message}</FormFeedback>}
					<FormText color='muted'>
						一項角色難以抗拒，或會對其感到恐懼的事物，而他在日常生活偶爾會為此付出一些代價。
						<br />
						例如: 他嗜賭，總是一轉眼就把報酬賭光
					</FormText>
				</FormGroup>
			</Form>
		</>
	)
}

export default CBBio
