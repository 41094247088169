import React from 'react'
import RawMasonry, { MasonryProps } from 'react-masonry-css'
import styled from 'styled-components'

const StyledMasonry = styled(RawMasonry)`
	display: flex;
	margin-left: -20px; /* gutter size offset */
	width: auto;

	> * {
		padding-left: 20px; /* gutter size */
		background-clip: padding-box;
	}
`

type Props = Omit<MasonryProps, 'className' | 'columnClassName'> & {
	columnClassName?: string
	className?: string
	children: React.ReactNode
}

const Masonry: React.FC<Props> = ({
	breakpointCols = 3,
	columnClassName = 'my-masonry-grid-col',
	className = 'my-masonry-grid',
	...others
}): JSX.Element => {
	return (
		<StyledMasonry
			breakpointCols={breakpointCols}
			columnClassName={columnClassName}
			className={className}
			{...others}
		/>
	)
}

export default React.memo(Masonry)
