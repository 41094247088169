import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

import { Button, Nav, NavItem, NavLink } from 'reactstrap'

import CBContext from './CBContext'

const NavContainer = styled.div`
	> * {
		display: none;
		padding: 1em;
		background-color: rgba(82, 77, 47, 0.08);

		&.show {
			display: block;
		}
	}
`

const StyledButtonWrapper = styled.div`
  text-align: center;
  margin-top; 1em;
`

type StatSelectProps = {
	onChange: (newValue: number) => void
	value: number
}

const StatSelect = ({ onChange, value }: StatSelectProps) => {
	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLSelectElement>) => {
			const newValue = parseInt(e.target.value)
			onChange(newValue)
		},
		[onChange]
	)

	return (
		<select onChange={handleChange} value={value}>
			<option value={0}>8</option>
			<option value={1}>9</option>
			<option value={2}>10</option>
			<option value={3}>11</option>
			<option value={4}>12</option>
			<option value={5}>13</option>
			<option value={6}>14</option>
			<option value={7}>15</option>
		</select>
	)
}

enum StatTab {
	Str,
	Dex,
	Con,
	Int,
	Wis,
	Cha,
}

const CBPointbuy = (): JSX.Element => {
	const { context, setContext, ci } = useContext(CBContext)
	const {
		stat_strength_raceBonus: strRaceBonus,
		stat_dexterity_raceBonus: dexRaceBonus,
		stat_constitution_raceBonus: conRaceBonus,
		stat_intelligence_raceBonus: intRaceBonus,
		stat_wisdom_raceBonus: wisRaceBonus,
		stat_charisma_raceBonus: chaRaceBonus,
	} = ci.getProperty()
	const [doSubmitOnce, setDoSubmitOnce] = useState<boolean>(false)

	const pointbuyCost = useCallback((i: number) => i + Math.max(i - 5, 0), [])

	const [activeTab, setActiveTab] = useState<StatTab>(StatTab.Str)

	const setStrAsActiveTab = useCallback(() => setActiveTab(StatTab.Str), [
		setActiveTab,
	])
	const setDexAsActiveTab = useCallback(() => setActiveTab(StatTab.Dex), [
		setActiveTab,
	])
	const setConAsActiveTab = useCallback(() => setActiveTab(StatTab.Con), [
		setActiveTab,
	])
	const setIntAsActiveTab = useCallback(() => setActiveTab(StatTab.Int), [
		setActiveTab,
	])
	const setWisAsActiveTab = useCallback(() => setActiveTab(StatTab.Wis), [
		setActiveTab,
	])
	const setChaAsActiveTab = useCallback(() => setActiveTab(StatTab.Cha), [
		setActiveTab,
	])

	const [strPointbuyBonus, setStrPointbuyBonus] = useState<number>(0)
	const [dexPointbuyBonus, setDexPointbuyBonus] = useState<number>(0)
	const [conPointbuyBonus, setConPointbuyBonus] = useState<number>(0)
	const [intPointbuyBonus, setIntPointbuyBonus] = useState<number>(0)
	const [wisPointbuyBonus, setWisPointbuyBonus] = useState<number>(0)
	const [chaPointbuyBonus, setChaPointbuyBonus] = useState<number>(0)

	const strScoreFinal = useMemo(
		() => 8 + strPointbuyBonus + (parseInt(strRaceBonus as string) || 0),
		[strPointbuyBonus, strRaceBonus]
	)
	const dexScoreFinal = useMemo(
		() => 8 + dexPointbuyBonus + (parseInt(dexRaceBonus as string) || 0),
		[dexPointbuyBonus, dexRaceBonus]
	)
	const conScoreFinal = useMemo(
		() => 8 + conPointbuyBonus + (parseInt(conRaceBonus as string) || 0),
		[conPointbuyBonus, conRaceBonus]
	)
	const intScoreFinal = useMemo(
		() => 8 + intPointbuyBonus + (parseInt(intRaceBonus as string) || 0),
		[intPointbuyBonus, intRaceBonus]
	)
	const wisScoreFinal = useMemo(
		() => 8 + wisPointbuyBonus + (parseInt(wisRaceBonus as string) || 0),
		[wisPointbuyBonus, wisRaceBonus]
	)
	const chaScoreFinal = useMemo(
		() => 8 + chaPointbuyBonus + (parseInt(chaRaceBonus as string) || 0),
		[chaPointbuyBonus, chaRaceBonus]
	)

	const strMod = useMemo(() => Math.floor((strScoreFinal - 10) / 2), [
		strScoreFinal,
	])
	const dexMod = useMemo(() => Math.floor((dexScoreFinal - 10) / 2), [
		dexScoreFinal,
	])
	const conMod = useMemo(() => Math.floor((conScoreFinal - 10) / 2), [
		conScoreFinal,
	])
	const intMod = useMemo(() => Math.floor((intScoreFinal - 10) / 2), [
		intScoreFinal,
	])
	const wisMod = useMemo(() => Math.floor((wisScoreFinal - 10) / 2), [
		wisScoreFinal,
	])
	const chaMod = useMemo(() => Math.floor((chaScoreFinal - 10) / 2), [
		chaScoreFinal,
	])

	const point = useMemo(
		() =>
			27 -
			pointbuyCost(strPointbuyBonus) -
			pointbuyCost(dexPointbuyBonus) -
			pointbuyCost(conPointbuyBonus) -
			pointbuyCost(intPointbuyBonus) -
			pointbuyCost(wisPointbuyBonus) -
			pointbuyCost(chaPointbuyBonus),
		[
			strPointbuyBonus,
			dexPointbuyBonus,
			conPointbuyBonus,
			intPointbuyBonus,
			wisPointbuyBonus,
			chaPointbuyBonus,
		]
	)

	const handleSubmit = useCallback(() => {
		if (point !== 0) {
			return
		}

		setContext((prev) => ({
			...prev,
			pointbuy: {
				strPointbuyBonus,
				dexPointbuyBonus,
				conPointbuyBonus,
				intPointbuyBonus,
				wisPointbuyBonus,
				chaPointbuyBonus,
			},
			pointbuyAttachableOAO: {
				override: {
					name: '自定義-點數分配',
					code: 'userdefined:pointbuy',
					category: 'pointbuy',
					features: [
						{
							name: '自定義-點數分配-能力值',
							code: 'userdefined:pointbuy:stat',
							description: `分配: ${strPointbuyBonus}/${dexPointbuyBonus}/${conPointbuyBonus}/${intPointbuyBonus}/${wisPointbuyBonus}/${chaPointbuyBonus}`,
							type: 'improvement',
							mutations: [
								{
									key: 'stat_strength_pointbuyBonus',
									formula: strPointbuyBonus.toString(),
								},
								{
									key: 'stat_dexterity_pointbuyBonus',
									formula: dexPointbuyBonus.toString(),
								},
								{
									key: 'stat_constitution_pointbuyBonus',
									formula: conPointbuyBonus.toString(),
								},
								{
									key: 'stat_intelligence_pointbuyBonus',
									formula: intPointbuyBonus.toString(),
								},
								{
									key: 'stat_wisdom_pointbuyBonus',
									formula: wisPointbuyBonus.toString(),
								},
								{
									key: 'stat_charisma_pointbuyBonus',
									formula: chaPointbuyBonus.toString(),
								},
							],
						},
					],
				},
			},
		}))
	}, [setContext, point])

	useEffect(() => {
		if (context.initPointbuyAttachableOAO) {
			const features =
				context.initPointbuyAttachableOAO?.override?.features || []
			if (features.length > 0) {
				const { mutations } = features[0]

				for (const m of mutations || []) {
					const value = parseInt(m.formula)
					switch (m.key) {
						case 'stat_strength_pointbuyBonus':
							setStrPointbuyBonus(value)
							break
						case 'stat_dexterity_pointbuyBonus':
							setDexPointbuyBonus(value)
							break
						case 'stat_constitution_pointbuyBonus':
							setConPointbuyBonus(value)
							break
						case 'stat_intelligence_pointbuyBonus':
							setIntPointbuyBonus(value)
							break
						case 'stat_wisdom_pointbuyBonus':
							setWisPointbuyBonus(value)
							break
						case 'stat_charisma_pointbuyBonus':
							setChaPointbuyBonus(value)
							break
					}
				}

				setDoSubmitOnce(true)
			}
		}
	}, [context.initPointbuyAttachableOAO])

	useEffect(() => {
		if (doSubmitOnce && point === 0) {
			handleSubmit()
			setDoSubmitOnce(false)
		}
	}, [doSubmitOnce, point])

	return (
		<>
			<StyledButtonWrapper>
				<Button
					size='lg'
					color='primary'
					disabled={point !== 0}
					onClick={handleSubmit}
				>
					儲存
				</Button>
			</StyledButtonWrapper>

			<Nav tabs>
				<NavItem>
					<NavLink className={classnames({ 'text-danger': point < 0 })}>
						<div>
							<small>剩餘點數</small>
						</div>
						<div>{point}</div>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === StatTab.Str })}
						onClick={setStrAsActiveTab}
					>
						<div>
							<small>力量</small>
						</div>
						<div>
							<strong>
								{strScoreFinal}({strMod})
							</strong>{' '}
							=
							<StatSelect
								onChange={setStrPointbuyBonus}
								value={strPointbuyBonus}
							/>{' '}
							+ {strRaceBonus}
						</div>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === StatTab.Dex })}
						onClick={setDexAsActiveTab}
					>
						<div>
							<small>敏捷</small>
						</div>
						<div>
							<strong>
								{dexScoreFinal}({dexMod})
							</strong>{' '}
							=
							<StatSelect
								onChange={setDexPointbuyBonus}
								value={dexPointbuyBonus}
							/>{' '}
							+ {dexRaceBonus}
						</div>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === StatTab.Con })}
						onClick={setConAsActiveTab}
					>
						<div>
							<small>體質</small>
						</div>
						<div>
							<strong>
								{conScoreFinal}({conMod})
							</strong>{' '}
							=
							<StatSelect
								onChange={setConPointbuyBonus}
								value={conPointbuyBonus}
							/>{' '}
							+ {conRaceBonus}
						</div>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === StatTab.Int })}
						onClick={setIntAsActiveTab}
					>
						<div>
							<small>智力</small>
						</div>
						<div>
							<strong>
								{intScoreFinal}({intMod})
							</strong>{' '}
							=
							<StatSelect
								onChange={setIntPointbuyBonus}
								value={intPointbuyBonus}
							/>{' '}
							+ {intRaceBonus}
						</div>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === StatTab.Wis })}
						onClick={setWisAsActiveTab}
					>
						<div>
							<small>感知</small>
						</div>
						<div>
							<strong>
								{wisScoreFinal}({wisMod})
							</strong>{' '}
							=
							<StatSelect
								onChange={setWisPointbuyBonus}
								value={wisPointbuyBonus}
							/>{' '}
							+ {wisRaceBonus}
						</div>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === StatTab.Cha })}
						onClick={setChaAsActiveTab}
					>
						<div>
							<small>魅力</small>
						</div>
						<div>
							<strong>
								{chaScoreFinal}({chaMod})
							</strong>{' '}
							=
							<StatSelect
								onChange={setChaPointbuyBonus}
								value={chaPointbuyBonus}
							/>{' '}
							+ {chaRaceBonus}
						</div>
					</NavLink>
				</NavItem>
			</Nav>

			<NavContainer>
				<div
					className={classnames({
						show: activeTab === StatTab.Str,
					})}
				>
					<p>
						力量代表了你的肉體強度，是戰士、野蠻人等近戰鬥士型角色的重要屬性。
					</p>
					<ul>
						<li>
							你以力量進行攻擊時(通常是近戰攻擊、擒抱)，命中及傷害均增加{' '}
							<strong>{strMod}</strong> 點
						</li>
						<li>
							使用力量技能時(例如:運動)，成功率增加 <strong>{strMod}</strong> 點
						</li>
						<li>
							進行力量豁免時(例如:抵抗水流)，成功率增加{' '}
							<strong>{strMod}</strong> 點
						</li>
						<li>
							你能負重 <strong>{5 * strScoreFinal}</strong> 磅而不受懲罰
						</li>
						<li>
							你能負重 <strong>{10 * strScoreFinal}</strong> 磅，但移動速度-10尺
						</li>
						<li>
							你能負重 <strong>{15 * strScoreFinal}</strong> 磅，但移動速度-20尺
						</li>
						<li>超過上述負重，你將沒法行動</li>
						<li>
							助跑10尺後，你能跳遠 <strong>{strScoreFinal}</strong> 尺，或跳高{' '}
							<strong>{3 + strMod}</strong> 尺；沒助跑情況下減半
						</li>
					</ul>
				</div>

				<div
					className={classnames({
						show: activeTab === StatTab.Dex,
					})}
				>
					<p>
						敏捷代表了你身體的靈活程度及協調能力，是盜賊、遊俠等靈活多變型角色的重要屬性。
					</p>
					<ul>
						<li>
							你以敏捷進行攻擊時(通常是遠程攻擊/使用靈巧武器時)，命中及傷害均增加
							<strong>{dexMod}</strong> 點
						</li>
						<li>
							使用敏捷技能時(例如:巧手、隱匿、體操)，成功率增加{' '}
							<strong>{dexMod}</strong> 點
						</li>
						<li>
							進行敏捷豁免時(例如:平衡)，成功率增加 <strong>{dexMod}</strong> 點
						</li>
						<li>
							戰鬥前決定先攻值時，你的先攻值增加
							<strong>{dexMod}</strong> 點
						</li>
						<li>
							你的防禦值增加 <strong>{dexMod}</strong> 點
							(穿著中甲時有上限；穿著重甲時不增加。)
						</li>
					</ul>
				</div>

				<div
					className={classnames({
						show: activeTab === StatTab.Con,
					})}
				>
					<p>
						體質代表了生命力，關乎到在戰場上可承受的傷害總額，對任何角色都重要。
					</p>
					<ul>
						<li>
							你能得到 <strong>{conMod}</strong> 點(每等級) 生命值
						</li>
						<li>
							進行體質豁免時(例如:抵抗毒性)，成功率增加{' '}
							<strong>{conMod}</strong> 點
						</li>
						<li>
							平常狀況下，你能閉氣 <strong>{conMod <= 0 ? 0.5 : conMod}</strong>{' '}
							分鐘而不受懲罰
						</li>
						<li>
							戰鬥中，你能維持窒息最多{' '}
							<strong>{conMod <= 0 ? 1 : conMod}</strong> 回合，其後生命值歸零
						</li>
					</ul>
				</div>

				<div
					className={classnames({
						show: activeTab === StatTab.Int,
					})}
				>
					<p>
						智力代表了記憶力、學習與運用知識的能力，是法師、術士或博士型角色的重要屬性
					</p>
					<ul>
						<li>
							以智力為施法屬性的法術，法術的命中及豁免難度則提升{' '}
							<strong>{intMod}</strong> 點。
						</li>
						<li>
							使用智力技能時(通常是回憶知識的時候，例如:奧術、宗教、歷史)，成功率增加
							<strong>{intMod}</strong> 點
						</li>
						<li>
							進行智力豁免時(例如:抵抗幻象)，成功率增加{' '}
							<strong>{intMod}</strong> 點
						</li>
					</ul>
				</div>

				<div
					className={classnames({
						show: activeTab === StatTab.Wis,
					})}
				>
					<p>
						感知代表了觀察力、敏銳度、運用經驗的能力，是德魯伊或偵探型角色的重要屬性
					</p>
					<ul>
						<li>
							以感知為施法屬性的法術，法術的命中及豁免難度則提升{' '}
							<strong>{wisMod}</strong> 點。
						</li>
						<li>
							使用感知技能時(通常是觀察的時候，例如:觀察、洞悉、醫術)，成功率增加{' '}
							<strong>{wisMod}</strong> 點
						</li>
						<li>
							進行感知豁免時(例如:抵抗精神入侵)，成功率增加{' '}
							<strong>{wisMod}</strong> 點
						</li>
					</ul>
				</div>

				<div
					className={classnames({
						show: activeTab === StatTab.Cha,
					})}
				>
					<p>
						魅力代表了存在感、吸引力、打動人心的能力，是魔導士或領袖型角色的重要屬性
						<br />
						<small className='text-muted'>
							(高魅力不代表那角色很美很帥(CoC的APP))
						</small>
					</p>
					<ul>
						<li>
							以魅力為施法屬性的法術，法術的命中及豁免難度則提升{' '}
							<strong>{chaMod}</strong> 點。
						</li>
						<li>
							使用魅力技能時(通常是影響他人感受的時候，例如:說服、欺暪、表演)，成功率增加
							<strong>{chaMod}</strong> 點
						</li>
						<li>
							進行魅力豁免時(例如:抵抗情緒波動)，成功率增加{' '}
							<strong>{chaMod}</strong> 點
						</li>
					</ul>
				</div>
			</NavContainer>
		</>
	)
}

export default CBPointbuy
