/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { RouteType } from '../types/system/route.type'

import CharacterBuilder from '../views/CharacterBuilder/CharacterBuilder'
import Character from '../views/CharacterDetail'
import Characters from '../views/Characters'
import Dashboard from '../views/Dashboard'
import GameEditor from '../views/GameEditor'
import Games from '../views/Games'
import RandomNpc from '../views/RandomNpc'
import UnderConstruction from '../views/UnderConstruction'

const routes: RouteType[] = [
	{
		path: '/dm/dashboard',
		name: '儀表板',
		icon: {
			category: 'ra',
			icon: 'compass',
		},
		component: Dashboard,
		inSidebar: true,
	},
	{
		path: '/dm/characters/:id/builder',
		name: '角色',
		icon: {
			category: 'ra',
			icon: 'helmet',
		},
		component: CharacterBuilder,
	},
	{
		path: '/dm/characters/:id',
		name: '角色詳細',
		icon: {
			category: 'ra',
			icon: 'helmet',
		},
		component: Character,
	},
	{
		path: '/dm/characters',
		name: '我的角色',
		icon: {
			category: 'ra',
			icon: 'helmet',
		},
		component: Characters,
		inSidebar: true,
	},
	{
		path: '/dm/players/:id',
		name: '玩家資料',
		icon: {
			category: 'bi',
			icon: 'people-fill',
		},
		component: UnderConstruction,
	},
	{
		path: '/dm/players',
		name: '玩家',
		icon: {
			category: 'bi',
			icon: 'people-fill',
		},
		component: UnderConstruction,
		inSidebar: true,
	},
	// {
	//   path: '/dm/games/new',
	//   name: '遊戲',
	//   icon: Icon'nc-icon nc-diamond',
	//   component: GameEditor,
	//   inSidebar: false,
	// },
	{
		path: '/dm/games/:id',
		name: '遊戲',
		icon: {
			category: 'bi',
			icon: 'controller',
		},
		component: GameEditor,
		inSidebar: false,
	},
	{
		path: '/dm/games',
		name: '遊戲',
		icon: {
			category: 'bi',
			icon: 'controller',
		},
		component: Games,
		inSidebar: true,
	},
	{
		path: '/dm/npc',
		name: '隨機NPC生成器',
		icon: {
			category: 'bi',
			icon: 'person',
		},
		component: RandomNpc,
		inSidebar: true,
	},
]
export default routes
