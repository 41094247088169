import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import {
	CharacterAttachable,
	CharacterFeature,
} from '../../types/character.type'
import { Cls } from '../../types/cls.type'

import { getClsAttachable, getClses } from '../../helpers/api.helper'

import { Alert, Button } from 'reactstrap'
import StyledReactSelect from '../../components/Select/StyledReactSelect'

import CBContext from './CBContext'
import CBFeatures from './CBFeatures'

const CBClses = (): JSX.Element => {
	const { context } = useContext(CBContext)
	const [selectedItem, setSelectedItem] = useState<Cls | null>(null)

	const _selectGetOptionLabel = useCallback((item: Cls) => item.name, [])
	const _selectIsOptionSelected = useCallback(
		(item: Cls) => item.id === selectedItem?.id,
		[selectedItem]
	)

	const clsesQuery = useQuery<Cls[]>(['clses'], () => getClses())

	useEffect(() => {
		if (context.initClsAttachableOAO && clsesQuery.data) {
			const found = clsesQuery.data.find(
				(item) => item.id === context.initClsAttachableOAO?.origin?.cls
			)
			if (found) {
				setSelectedItem(found)
			}
		}
	}, [context.initClsAttachableOAO, clsesQuery.data])

	if (clsesQuery.isLoading) {
		return <div>Loading...</div>
	}

	if (clsesQuery.isError) {
		return <div>Error</div>
	}

	return (
		<>
			{/* <Row>
				{query.data?.map((item) => (
					<Col xs={12} sm={6} md={4} lg={2} key={item.id}>
						{item.name}
					</Col>
				))}
			</Row> */}

			<StyledReactSelect
				options={clsesQuery.data}
				getOptionLabel={_selectGetOptionLabel}
				isOptionSelected={_selectIsOptionSelected}
				onChange={setSelectedItem}
				value={selectedItem}
			/>

			<div>{selectedItem && <CBClsDetail cls={selectedItem} />}</div>
		</>
	)
}

const StyledCBClsDetailWrapper = styled.div`
	padding: 1em;
	margin-top: 1em;
	border-radius: 12px;
	background-color: rgba(82, 77, 47, 0.08);
`

const CBClsDetail = ({ cls }: { cls: Cls }) => {
	const { context, setContext } = useContext(CBContext)
	const [isInited, setInited] = useState<boolean>(false)
	const [doSubmitOnce, setDoSubmitOnce] = useState<boolean>(false)
	const [overridedFeaturesMap, setOverridedFeaturesMap] = useState<
		Record<string, CharacterFeature>
	>({})
	const [error, setError] = useState<string>('')

	const attachableQuery = useQuery<CharacterAttachable>(
		['cls', cls.id, 'attachable'],
		() => getClsAttachable(cls.id),
		{
			enabled: !!cls,
		}
	)

	const handleFeatureChange = useCallback(
		(f: CharacterFeature) => {
			setOverridedFeaturesMap((prev) => ({
				...prev,
				[f.overridedCode || f.code]: f,
			}))
		},
		[setOverridedFeaturesMap]
	)

	const handleSubmit = useCallback(() => {
		for (const f of attachableQuery.data?.features || []) {
			if (
				f.options &&
				f.options.length > 0 &&
				(!overridedFeaturesMap[f.code] ||
					overridedFeaturesMap[f.code].amount !== f.amount)
			) {
				setError(`你要揀選 ${f.name} 中的其中 ${f.amount || 1}個選項。`)
				return
			}
		}

		setContext((prev) => ({
			...prev,
			cls,
			clsAttachableOAO: {
				origin: attachableQuery.data,
				override: { features: Object.values(overridedFeaturesMap) },
			},
		}))
		setError('')
	}, [setContext, setError, cls, attachableQuery.data, overridedFeaturesMap])

	useEffect(() => {
		if (context.initClsAttachableOAO) {
			const { override } = context.initClsAttachableOAO
			const newOverridedFeaturesMap: Record<string, CharacterFeature> = {}
			for (const item of override?.features || []) {
				newOverridedFeaturesMap[item.overridedCode || item.code] = item
			}

			setOverridedFeaturesMap(newOverridedFeaturesMap)
			setDoSubmitOnce(true)
		}
		setInited(true)
	}, [context.initClsAttachableOAO])

	useEffect(() => {
		if (doSubmitOnce && attachableQuery.data && isInited) {
			handleSubmit()
			setDoSubmitOnce(false)
		}
	}, [doSubmitOnce, attachableQuery.data, isInited])

	if (attachableQuery.isLoading || !isInited) {
		return <StyledCBClsDetailWrapper>Loading...</StyledCBClsDetailWrapper>
	}

	if (attachableQuery.isError) {
		return <StyledCBClsDetailWrapper>Error</StyledCBClsDetailWrapper>
	}

	return (
		<StyledCBClsDetailWrapper>
			<div className='text-center'>
				<Button size='lg' color='primary' onClick={handleSubmit}>
					選擇此職業
				</Button>
			</div>
			<Alert isOpen={!!error} color='danger'>
				{error}
			</Alert>
			<p>
				選擇 <strong>{cls.name}</strong> 作為初始職業的話，角色會得到以下特性:
			</p>
			<CBFeatures
				features={attachableQuery.data?.features}
				hidden={['metadata']}
				sorting={['improvement', 'static', 'triggered', 'activated', 'mundane']}
				onFeatureChange={handleFeatureChange}
				overridedFeaturesMap={overridedFeaturesMap}
			/>
		</StyledCBClsDetailWrapper>
	)
}

export default CBClses
