import React, { useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import addMonths from 'date-fns/addMonths'
import endOfMonth from 'date-fns/endOfMonth'
import classnames from 'classnames'

import { Game } from '../types/game.type'

import { getGamesByDateRange } from '../helpers/api.helper'

import { Button } from 'reactstrap'
import Calendar from '../components/Calendar/Calendar'
import PageBrandname from '../components/Page/PageBrand'
import PageTitle from '../components/Page/PageTitle'

import '../assets/scss/views/Games.view.scss'

const _titleAccessor = (game: Game) => `${game.code} ${game.title}`
const _tooltipAccessor = (game: Game) => `${game.code} ${game.title}`
const _startAccessor = (game: Game) => new Date(game.startAt)
const _endAccessor = (game: Game) => new Date(game.endAt)
const _eventPropGetter = (game: Game) => {
	return {
		className: classnames('event-game', `event-game-status-${game.status}`),
	}
}

const _componentEvent = ({ event: game }: { event: Game }) => {
	return (
		<>
			<span className='event-game-code'>{game.code}</span>{' '}
			<span className='event-game-title'>{game.title}</span>
		</>
	)
}
const _components = {
	event: _componentEvent,
}

const Games = (): JSX.Element => {
	const routeMatch = useRouteMatch()
	const queryClient = useQueryClient()
	const history = useHistory()
	const [games, setGames] = useState<Game[]>([])

	const handleCalendarMonthChange = useCallback(
		({ start }: { start: Date; end: Date }) => {
			const prevMonth = addMonths(start, -1)
			const nextMonth = addMonths(start, 1)
			const thisMonth = start

			Promise.all(
				[prevMonth, thisMonth, nextMonth].map((date) => {
					const queryKey = `${date?.getFullYear()}-${date?.getMonth()}`
					const endOfMonthOfDate = endOfMonth(date)
					return queryClient.fetchQuery<Game[]>(
						['games', queryKey],
						() => getGamesByDateRange(date, endOfMonthOfDate),
						{
							staleTime: 60000,
						}
					)
				})
			).then((arrayOfGames: Game[][]) => {
				setGames(([] as Game[]).concat(...arrayOfGames))
			})
		},
		[queryClient, setGames]
	)

	const handleCalendarSelectEvent = useCallback(
		(game) => {
			history.push(`${routeMatch.url}/${game.id}`)
		},
		[history]
	)

	return (
		<>
			<PageTitle>遊戲時間表</PageTitle>
			<PageBrandname>遊戲時間表</PageBrandname>
			<div className='content'>
				<div style={{ textAlign: 'right' }}>
					<Link to={`${routeMatch.url}/new`}>
						<Button color='primary'>新遊戲</Button>
					</Link>
				</div>

				<Calendar
					onMonthChange={handleCalendarMonthChange}
					onSelectEvent={handleCalendarSelectEvent}
					events={games}
					startAccessor={_startAccessor}
					endAccessor={_endAccessor}
					titleAccessor={_titleAccessor}
					tooltipAccessor={_tooltipAccessor}
					eventPropGetter={_eventPropGetter}
					components={_components}
					style={{ height: 600 }}
				/>
			</div>
		</>
	)
}

export default Games
