/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { Character } from '../../types/character.type'
import { Game } from '../../types/game.type'
import { User } from '../../types/user.type'

import { getAnythingbySearch } from '../../helpers/api.helper'
import authHelper from '../../helpers/auth.helper'

// import { Link } from 'react-router-dom'
import {
	Collapse,
	Container,
	// NavItem,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	Navbar,
	NavbarBrand,
	NavbarToggler,
} from 'reactstrap'

import { RootState } from '../../store'
import Avatar from '../Avatar/Avatar'
import SearchInput from '../SearchInput/SearchInput'
import { History, Location } from 'history'

const StyledDropdown = styled(Dropdown)`
	margin-left: 1em;
	.nav-link {
		padding-right: 0 !important;
		margin-right: 0 !important;
	}
`
const StyledAvatar = styled(Avatar)`
	height: 40px;
	widthl: 40xp;
`
const StyledUserName = styled.span`
	margin-left: 0.5em;
`

const handleLoadSearch = (
	inputValue: string,
	callback: (
		result: { label: string; options: Character[] | Game[] | User[] }[]
	) => void
) => {
	getAnythingbySearch(inputValue).then((result) =>
		callback([
			{ label: '角色', options: result.characters },
			{ label: '玩家', options: result.users },
			{ label: '遊戲', options: result.games },
		])
	)
}

const searchOptionLabel = (item: Character | Game | User) => {
	if ((item as Character).player) {
		const c = item as Character
		return `${c.code} ${c.name}`
	} else if ((item as Game).title) {
		const g = item as Game
		return `${g.code} ${g.title}`
	} else if ((item as User).username) {
		const u = item as User
		return `${u.code} ${u.name}`
	}

	return ''
}

type Props = {
	location: Location
	history: History
}

const handleClickSignOut = () => {
	authHelper.signOut()
	window.location.href = '/'
}

const Header = (props: Props) => {
	const reactRouterHistory = useHistory()
	const routeMatch = useRouteMatch()
	const authUser = useSelector((state: RootState) => state.authUser.user)
	const brandName = useSelector((state: RootState) => state.page.brandName)

	const sidebarToggle = useRef<HTMLButtonElement>(null)

	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [isDropdownOpen, setisDropdownOpen] = useState<boolean>(false)
	const [color, setColor] = useState<string>('transparent')

	const toggle = useCallback(() => {
		if (isOpen) {
			setColor('transparent')
		} else {
			setColor('dark')
		}
		setIsOpen((prev) => !prev)
	}, [isOpen, setColor, setIsOpen])

	const dropdownToggle = useCallback(() => {
		setisDropdownOpen((prev) => !prev)
	}, [setisDropdownOpen])

	const openSidebar = useCallback(() => {
		document.documentElement.classList.toggle('nav-open')
		if (sidebarToggle && sidebarToggle.current) {
			sidebarToggle.current.classList.toggle('toggled')
		}
	}, [document, sidebarToggle])

	const handleChangeSearchOption = useCallback(
		(item: Character | Game | User | null) => {
			if (item) {
				if ((item as Character).player) {
					const c = item as Character
					reactRouterHistory.push(`${routeMatch.url}/characters/${c.id}`)
				} else if ((item as Game).title) {
					const g = item as Game
					reactRouterHistory.push(`${routeMatch.url}/games/${g.id}`)
				} else if ((item as User).username) {
					const u = item as User
					reactRouterHistory.push(`${routeMatch.url}/players/${u.id}`)
				}
			}
		},
		[reactRouterHistory, routeMatch.url]
	)

	// componentDidUpdate(prevProps: Props) {
	// 	if (
	// 		window.innerWidth < 993 &&
	// 		prevProps.history.location.pathname !== prevProps.location.pathname &&
	// 		document.documentElement.className.indexOf('nav-open') !== -1
	// 	) {
	// 		document.documentElement.classList.toggle('nav-open')
	// 		if (sidebarToggle && sidebarToggle.current) {
	// 			sidebarToggle.current.classList.toggle('toggled')
	// 		}
	// 	}
	// }
	return (
		// add or remove classes depending if we are on full-screen-maps page or not
		<Navbar
			color={
				props.location.pathname.indexOf('full-screen-maps') !== -1
					? 'dark'
					: color
			}
			expand='lg'
			className={
				props.location.pathname.indexOf('full-screen-maps') !== -1
					? 'navbar-absolute fixed-top'
					: 'navbar-absolute fixed-top ' +
					  (color === 'transparent' ? 'navbar-transparent ' : '')
			}
		>
			<Container fluid>
				<div className='navbar-wrapper'>
					<div className='navbar-toggle'>
						<button
							type='button'
							ref={sidebarToggle}
							className='navbar-toggler'
							onClick={() => openSidebar()}
						>
							<span className='navbar-toggler-bar bar1' />
							<span className='navbar-toggler-bar bar2' />
							<span className='navbar-toggler-bar bar3' />
						</button>
					</div>
					<NavbarBrand href='/'>{brandName}</NavbarBrand>
				</div>
				<NavbarToggler onClick={toggle}>
					<span className='navbar-toggler-bar navbar-kebab' />
					<span className='navbar-toggler-bar navbar-kebab' />
					<span className='navbar-toggler-bar navbar-kebab' />
				</NavbarToggler>
				<Collapse isOpen={isOpen} navbar className='justify-content-end'>
					<SearchInput<Character | Game | User>
						placeholder='搜尋角色、玩家、遊戲…'
						loadOptions={handleLoadSearch}
						getOptionLabel={searchOptionLabel}
						onChange={handleChangeSearchOption}
						isOptionSelected={() => false}
						escapeClearsValue={true}
						isClearable
					/>
					<Nav navbar>
						{/* <NavItem>
                <Link to="#pablo" className="nav-link btn-magnify">
                  <i className="nc-icon nc-layout-11" />
                  <p>
                    <span className="d-lg-none d-md-block">Stats</span>
                  </p>
                </Link>
              </NavItem> */}
						<StyledDropdown nav isOpen={isDropdownOpen} toggle={dropdownToggle}>
							<DropdownToggle nav>
								<StyledAvatar src={authUser?.portraitImage?.url} />
								<StyledUserName>{authUser?.name}</StyledUserName>
							</DropdownToggle>
							<DropdownMenu right>
								<DropdownItem tag='a' onClick={handleClickSignOut}>
									登出
								</DropdownItem>
							</DropdownMenu>
						</StyledDropdown>
						{/* <NavItem>
                <Link to="#pablo" className="nav-link btn-rotate">
                  <i className="nc-icon nc-settings-gear-65" />
                  <p>
                    <span className="d-lg-none d-md-block">Account</span>
                  </p>
                </Link>
              </NavItem> */}
					</Nav>
				</Collapse>
			</Container>
		</Navbar>
	)
}

export default Header
