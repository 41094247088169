import React from 'react'
import styled from 'styled-components'

import Progress from 'reactstrap/lib/Progress'

const StyledCaption = styled.div`
	display: flex;
	justify-content: space-between;
`
const StyledProgress = styled(Progress)`
	height: 4px;
`

type Props = {
	xp: number
}

const getLvInfo = (xp: number) => {
	const lvs = [
		0,
		300,
		900,
		2700,
		6500,
		14000,
		23000,
		34000,
		48000,
		64000,
		85000,
		100000,
		120000,
		140000,
		165000,
		195000,
		225000,
		265000,
		305000,
		355000,
	]
	const lv = lvs.findIndex((cap) => xp < cap)
	const cap = lvs[lv]
	const prevCap = lv > 0 ? lvs[lv - 1] : 0

	return {
		lv,
		current: xp,
		cap,
		percentage:
			100 * (cap - prevCap > 0 ? (xp - prevCap) / (cap - prevCap) : 0),
	}
}

const CharacterLevelAndXpProgress = ({ xp }: Props): JSX.Element => {
	const { lv, current, cap, percentage } = getLvInfo(xp)

	return (
		<>
			<StyledCaption>
				<small className='text-muted'>等級 {lv}</small>
				<small className='text-muted'>
					{current}/{cap}XP
				</small>
			</StyledCaption>
			<StyledProgress color='info' value={percentage} />
		</>
	)
}

export default React.memo(CharacterLevelAndXpProgress)
