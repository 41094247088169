import React from 'react'
import styled from 'styled-components'

const portraitPlaceholder = '/assets/images/portrait-placeholder.png'

const StyledImg = styled.img`
	border-radius: 50%;
`

type Props = React.ImgHTMLAttributes<HTMLElement>

const Avatar = ({ src, ...others }: Props): JSX.Element => {
	return <StyledImg src={src || portraitPlaceholder} {...others} />
}

export default React.memo(Avatar)
