import React from 'react'
import { useAsync } from 'react-async'

import randomNpcHelper, { Character_NPC } from '../helpers/randomNpc.helepr'

import { Button, FormGroup } from 'reactstrap'
import PageBrandname from '../components/Page/PageBrand'
import PageTitle from '../components/Page/PageTitle'

const randomNpcFn = () => {
	return randomNpcHelper.random()
}

const RandomNpc = (): JSX.Element => {
	const npcAsync = useAsync<Character_NPC>({
		deferFn: randomNpcFn,
		onReject: (e) => {
			console.error('something wrong!', e)
		},
	})

	const run = () => {
		npcAsync.run()
	}

	return (
		<>
			<PageTitle>NPC生成器</PageTitle>
			<PageBrandname>NPC生成器</PageBrandname>
			<div className='content'>
				<FormGroup>
					<Button color='primary' onClick={run}>
						Randomize
					</Button>
				</FormGroup>

				<code>
					{npcAsync.isResolved ? JSON.stringify(npcAsync.data, null, 2) : '{}'}
				</code>
			</div>
		</>
	)
}

export default RandomNpc
