import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import {
	Controller as ReactHookFormController,
	NestedValue,
	useFieldArray,
	useForm,
	useWatch,
} from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
	Link,
	Prompt,
	useHistory as useRouterHistory,
	useParams as useRouterParams,
} from 'react-router-dom'
import { toast } from 'react-toastify'
import addHours from 'date-fns/addHours'
import addYears from 'date-fns/addYears'
import differenceInCalendarISOWeeks from 'date-fns/differenceInCalendarISOWeeks'
import lightFormat from 'date-fns/lightFormat'
import classnames from 'classnames'
import copyToClipboard from 'copy-to-clipboard'

import { Character } from '../types/character.type'
import { City } from '../types/city.type'
import {
	Game,
	Game_RequestDTO,
	GameCharacterAndReward,
	GameJournal,
	GameStatus,
} from '../types/game.type'
import { User } from '../types/user.type'

import {
	getCharactersBySearch,
	getCities,
	getDMs,
	getGameById,
	patchGameToCompletedById,
	patchGameToDraftById,
	patchGameToPublishedById,
	postGame,
	putGameById,
} from '../helpers/api.helper'

import {
	Alert,
	Breadcrumb,
	BreadcrumbItem,
	Card,
	CardBody,
	Col,
	Form,
	FormFeedback,
	FormGroup,
	FormText,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap'
import Button from '../components/Button'
import HelperBox from '../components/HelperBox'
import PageBrandname from '../components/Page/PageBrand'
import PageTitle from '../components/Page/PageTitle'
import StyledReactAsyncSelect from '../components/Select/StyledReactAsyncSelect'
import StyledReactSelect from '../components/Select/StyledReactSelect'

type GameCharacterAndRewardDTO = Omit<
	GameCharacterAndReward,
	'character' | 'items'
> & {
	character: NestedValue<Character>
	items: string
}
type GameJournalDTO = GameJournal
type GameDTO = Omit<
	Game,
	'startAt' | 'endAt' | 'city' | 'dm' | 'characterAndRewards' | 'journals'
> & {
	city: NestedValue<City> | null
	dm: NestedValue<User> | null
	startAtDate: string
	startAtTime: string
	endAtDate: string
	endAtTime: string
	characterAndRewards: GameCharacterAndRewardDTO[]
	journals: GameJournalDTO[]
}
const transformGameToGameDTO = ({
	startAt,
	endAt,
	city,
	dm,
	characterAndRewards,
	journals,
	...others
}: Game): GameDTO => {
	const startAtObj = new Date(startAt)
	const endAtObj = new Date(endAt)
	return {
		city: city as NestedValue<City>,
		dm: dm as NestedValue<User>,
		startAtDate: lightFormat(startAtObj, 'yyyy-MM-dd'),
		startAtTime: lightFormat(startAtObj, 'HH:mm'),
		endAtDate: lightFormat(endAtObj, 'yyyy-MM-dd'),
		endAtTime: lightFormat(endAtObj, 'HH:mm'),
		characterAndRewards:
			characterAndRewards?.map(({ items, character, ...rest }) => ({
				character: character as NestedValue<Character>,
				items: items.map(({ name }) => name).join('\n'),
				...rest,
			})) || [],
		journals: journals || [],
		...others,
	}
}
const transformGameDTOToGame = (gameDto: GameDTO): Game => {
	const {
		startAtDate,
		startAtTime,
		endAtDate,
		endAtTime,
		city,
		dm,
		characterAndRewards,
		journals,
		...others
	} = gameDto
	return {
		city: city as City,
		dm: dm as User,
		startAt: new Date(`${startAtDate}T${startAtTime}`).toISOString(),
		endAt: new Date(`${endAtDate}T${endAtTime}`).toISOString(),
		characterAndRewards:
			characterAndRewards?.map(({ items, id, ...rest }) => ({
				id: id || undefined,
				items: items
					.split('\n')
					.filter((_) => !!_)
					.map((name) => ({ name })),
				...rest,
			})) || [],
		journals:
			journals?.map(({ id, ...rest }) => ({
				id: id || undefined,
				...rest,
			})) || [],
		...others,
	}
}
const transformGameToGameRequestDTO = (game: Game) => {
	return {
		...game,
		city: game.city?.id,
		dm: game.dm?.id,
		characterAndRewards: game.characterAndRewards.map((cr) => ({
			...cr,
			character: cr.character?.id,
		})),
	}
}
const transformGameDTOToGameRequestDTO = (gameDto: GameDTO) => {
	const game = transformGameDTOToGame(gameDto)
	return {
		...game,
		city: game.city?.id,
		dm: game.dm?.id,
		characterAndRewards: game.characterAndRewards.map((cr) => ({
			...cr,
			character: cr.character?.id,
		})),
	}
}

enum TabIndex {
	CONFIG,
	PLAYERS_AND_REWARDS,
	JOURNALS,
	OTHERS,
}

const cityOptionLabel = (city: City) =>
	city ? `${city.name} ${city.code} (${city.shopName})` : ''
const dmOptionLabel = (dm: User) => (dm ? `${dm.username} (${dm.code})` : '')
const characterOptionLabel = (character: Character) =>
	character ? `${character.code} ${character.name}` : ''

const today = new Date()
today.setHours(20)
today.setMinutes(0)
today.setSeconds(0)
const todayAddFourHours = addHours(today, 4)
const worldToday = addYears(today, -758)

const defaultValues = {
	city: null,
	dm: null,
	lvMin: 2,
	lvMax: 3,
	capacityMin: 3,
	capacityMax: 6,
	startAtDate: lightFormat(today, 'yyyy-MM-dd'),
	startAtTime: lightFormat(today, 'HH:mm'),
	endAtDate: lightFormat(todayAddFourHours, 'yyyy-MM-dd'),
	endAtTime: lightFormat(todayAddFourHours, 'HH:mm'),
	worldStartAt: lightFormat(worldToday, 'yyyy-MM-dd'),
	worldEndAt: lightFormat(worldToday, 'yyyy-MM-dd'),
	characterAndRewards: [],
	journals: [],
	status: GameStatus.Draft,
}

const handleAsyncSelectLoadCharacters = (
	inputValue: string,
	callback: (characters: Character[]) => void
) => {
	getCharactersBySearch(inputValue).then((characters) => callback(characters))
}

const GameEditor = (): JSX.Element => {
	const routerHistory = useRouterHistory()
	const {
		control,
		register,
		handleSubmit,
		watch,
		errors,
		formState,
		setError,
		reset,
	} = useForm<GameDTO>({
		defaultValues,
	})
	const characterAndRewardsFieldArray = useFieldArray<
		GameCharacterAndRewardDTO,
		'faid'
	>({
		control,
		name: 'characterAndRewards',
		keyName: 'faid',
	})
	const handleClickAddCharacterAndReward = useCallback(() => {
		characterAndRewardsFieldArray.append({
			id: '',
			xp: 0,
			gp: 0,
			character: undefined,
			items: '',
			remark: '',
		})
	}, [characterAndRewardsFieldArray])
	const handleClickCloneCharacterAndReward = useCallback(
		(i: number) => {
			const value = watch<string, GameCharacterAndRewardDTO>(
				`characterAndRewards[${i}]`
			)
			characterAndRewardsFieldArray.append({
				...value,
				id: '',
				character: value.character as NestedValue<Character>,
			})
		},
		[watch, characterAndRewardsFieldArray]
	)
	const handleClickRemoveCharacterAndReward = useCallback(
		(i: number) => {
			if (window.confirm('確定刪除嗎？')) {
				characterAndRewardsFieldArray.remove(i)
			}
		},
		[characterAndRewardsFieldArray]
	)

	const journalsFieldArray = useFieldArray<GameJournalDTO, 'faid'>({
		control,
		name: 'journals',
		keyName: 'faid',
	})

	const handleClickAddJournal = useCallback(() => {
		journalsFieldArray.append({
			id: '',
			subject: '',
			description: '',
		})
	}, [journalsFieldArray])
	const handleClickCloneJournal = useCallback(
		(i: number) => {
			const value = watch<string, GameJournalDTO>(`journals[${i}]`)
			journalsFieldArray.append({
				...value,
				id: '',
			})
		},
		[watch, journalsFieldArray]
	)
	const handleClickRemoveJournal = useCallback(
		(i: number) => {
			if (window.confirm('確定刪除嗎？')) {
				journalsFieldArray.remove(i)
			}
		},
		[journalsFieldArray]
	)

	const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.CONFIG)
	const handleToggleTab = useCallback(
		(index: TabIndex) => {
			setTabIndex(index)
		},
		[setTabIndex]
	)

	const [isPublishModalOpened, setIsPublishModalOpened] = useState<boolean>(
		false
	)
	const handleTogglePublishModal = useCallback(() => {
		setIsPublishModalOpened((prev) => !prev)
	}, [setIsPublishModalOpened])

	const handleClickPrePublish = useCallback(() => {
		handleTogglePublishModal()
	}, [handleTogglePublishModal])

	const [isDraftModalOpened, setIsDraftModalOpened] = useState<boolean>(false)
	const handleToggleDraftModal = useCallback(() => {
		setIsDraftModalOpened((prev) => !prev)
	}, [setIsDraftModalOpened])

	const handleClickPreDraft = useCallback(() => {
		handleToggleDraftModal()
	}, [handleToggleDraftModal])

	const [isCompleteModalOpened, setIsCompleteModalOpened] = useState<boolean>(
		false
	)
	const handleToggleCompleteModal = useCallback(() => {
		setIsCompleteModalOpened((prev) => !prev)
	}, [setIsCompleteModalOpened])

	const handleClickPreComplete = useCallback(() => {
		handleToggleCompleteModal()
	}, [handleToggleCompleteModal])

	const [isPromoTextModalOpened, setIsPromoTextModalOpened] = useState<boolean>(
		false
	)
	const handleTogglePromoTextModal = useCallback(() => {
		setIsPromoTextModalOpened((prev) => !prev)
	}, [setIsPromoTextModalOpened])

	useBeforeunload(() => (formState.isDirty ? '' : undefined))

	const citiesQuery = useQuery<City[]>('cities', () => getCities(), {
		staleTime: Infinity,
	})
	const dmsQuery = useQuery<User[]>('dms', () => getDMs(), {
		staleTime: Infinity,
	})

	const [gameCodePlaceholder, setGameCodePlaceholder] = useState<string>('')
	const gameCodeComponents = useWatch<{
		startAtDate: string
		city: City
		dm: User
	}>({
		control,
		name: ['startAtDate', 'city', 'dm'],
	})
	const promoTextComponents = useWatch<
		Pick<
			GameDTO,
			| 'title'
			| 'code'
			| 'startAtDate'
			| 'startAtTime'
			| 'worldStartAt'
			| 'dm'
			| 'city'
			| 'lvMin'
			| 'lvMax'
			| 'capacityMin'
			| 'capacityMax'
			| 'description'
			| 'remark'
		>
	>({
		control,
		name: [
			'title',
			'code',
			'startAtDate',
			'startAtTime',
			'worldStartAt',
			'dm',
			'city',
			'lvMin',
			'lvMax',
			'capacityMin',
			'capacityMax',
			'description',
			'remark',
		],
	})
	const promoText = useMemo(() => {
		const wtt = promoTextComponents
		return `#${wtt.code}\n
Game ${wtt.code} - ${wtt.title}
時間: ${wtt.startAtDate} ${wtt.startAtTime}
地點: ${wtt.city?.name}
等級: lv${wtt.lvMin}-${wtt.lvMax}
DM: ${wtt.dm?.name}
人數: ${wtt.capacityMin}-${wtt.capacityMax}
費用: lv1-4 -> $100, lv5+ -> $120 (收費包括: 場地費用及DM服務費 *)
〔任務日期：第三紀元${lightFormat(
			wtt.worldStartAt ? new Date(wtt.worldStartAt) : new Date(),
			`yyyy年MM月dd日`
		)}〕

故事:
${wtt.description}

請提供 角色編號+角色名+Lv+種族+職業+玩家名稱
e.g. 00016-GO01 Lancevalo Lv5 human bard Ryan/
1. 
2. 
3. 
4. 
5. 
6. 

${wtt.remark}`
	}, [promoTextComponents])

	const handleCopyPromoText = useCallback(() => {
		copyToClipboard(promoText)
		toast.success('已複製文字到黏貼板。')
	}, [promoText])

	useEffect(() => {
		const { startAtDate, city, dm } = gameCodeComponents

		if (startAtDate && city && dm) {
			const weekNumberCode = differenceInCalendarISOWeeks(
				new Date(startAtDate),
				new Date(2020, 1, 17)
			)
				.toString()
				.padStart(4, '0')
			const cityCode = city?.code || 'xx'
			const dmCode = dm?.code || 'yy'

			setGameCodePlaceholder(`${cityCode}-${weekNumberCode}${dmCode}`)
		}
	}, [gameCodeComponents])

	const { id } = useRouterParams<{ id: string }>()
	const queryClient = useQueryClient()
	const gameQuery = useQuery(['game', id], () => getGameById(id), {
		enabled: id !== 'new',
		cacheTime: 10,
		staleTime: Infinity,
		refetchOnMount: 'always',
		select: (game: Game) => {
			const gameDTO = transformGameToGameDTO(game)
			reset(gameDTO)
			return game
		},
	})

	const gameMutation = useMutation(
		(data: { game: Game_RequestDTO; options?: { newStatus?: GameStatus } }) => {
			const { game: newGame, options } = data
			if (id === 'new') {
				return postGame(newGame)
			}

			if (options?.newStatus) {
				switch (options?.newStatus) {
					case GameStatus.Draft:
						return patchGameToDraftById(newGame.id)
					case GameStatus.Published:
						return patchGameToPublishedById(newGame.id)
					case GameStatus.Completed:
						return patchGameToCompletedById(newGame.id)
				}
			}

			return putGameById(newGame.id, newGame)
		},
		{
			onSuccess: (game: Game) => {
				queryClient.setQueryData(['game', game.id], game)
				reset(transformGameToGameDTO(game))
				if (id === 'new') {
					toast.success(
						<span>
							已建立{' '}
							<strong>
								[{game.code}] {game.title}
							</strong>
						</span>
					)
					routerHistory.replace(`/dm/games/${game.id}`)
				} else {
					toast.success(
						<span>
							已更新{' '}
							<strong>
								[{game.code}] {game.title}
							</strong>
						</span>
					)
					queryClient.invalidateQueries('games')
				}
			},
		}
	)

	const onSubmit = useCallback(
		(data: Record<string, unknown>) => {
			const presubmitGame = transformGameDTOToGameRequestDTO({
				...data,
				code: data.code || gameCodePlaceholder,
			} as GameDTO)
			// Validate startAt and endAt
			if (presubmitGame.endAt < presubmitGame.startAt) {
				setError('endAtDate', {
					message: '結束日期不能比開始日期還早。',
				})
				setError('endAtTime', {
					message: ' ',
				})
				return
			}

			gameMutation.mutate({
				game: presubmitGame,
			})
		},
		[gameMutation, gameCodePlaceholder]
	)

	const handleClickConfirmPublish = useCallback(() => {
		const _game = gameQuery.data
		if (_game) {
			gameMutation.mutate(
				{
					game: transformGameToGameRequestDTO(_game),
					options: { newStatus: GameStatus.Published },
				},
				{
					onSuccess: () => {
						setIsPublishModalOpened(false)
					},
				}
			)
		}
	}, [gameMutation, gameQuery.data])

	const handleClickConfirmComplete = useCallback(() => {
		const _game = gameQuery.data
		if (_game) {
			gameMutation.mutate(
				{
					game: transformGameToGameRequestDTO(_game),
					options: { newStatus: GameStatus.Completed },
				},
				{
					onSuccess: () => {
						setIsCompleteModalOpened(false)
					},
				}
			)
		}
	}, [gameMutation, gameQuery.data])

	const handleClickConfirmDraft = useCallback(() => {
		const _game = gameQuery.data
		if (_game) {
			gameMutation.mutate(
				{
					game: transformGameToGameRequestDTO(_game),
					options: { newStatus: GameStatus.Draft },
				},
				{
					onSuccess: () => {
						setIsDraftModalOpened(false)
					},
				}
			)
		}
	}, [gameMutation, gameQuery.data])

	const gameStatus = useWatch<GameStatus>({
		control,
		name: 'status',
	})

	if (gameQuery.isLoading) {
		return (
			<>
				<PageTitle>遊戲</PageTitle>
				<PageBrandname>遊戲</PageBrandname>
				<div className='content'>
					<Breadcrumb>
						<BreadcrumbItem>
							<Link to='.'>遊戲時間表</Link>
						</BreadcrumbItem>
						<BreadcrumbItem active>讀取中...</BreadcrumbItem>
					</Breadcrumb>
					<div>讀取中...</div>
				</div>
			</>
		)
	}

	if (gameQuery.isError) {
		return (
			<>
				<PageTitle>遊戲</PageTitle>
				<PageBrandname>遊戲</PageBrandname>
				<div className='content'>
					<Breadcrumb>
						<BreadcrumbItem>
							<Link to='.'>遊戲時間表</Link>
						</BreadcrumbItem>
					</Breadcrumb>
					<Alert color='danger'>讀取失敗。</Alert>
				</div>
			</>
		)
	}

	return (
		<>
			<PageTitle>
				[{gameQuery.data?.code || ''}] {gameQuery.data?.title || ''}
			</PageTitle>
			<PageBrandname>{gameQuery.data?.title || ''}</PageBrandname>
			<Prompt
				when={formState.isDirty}
				message='你未儲存你的修改，確定要離開嗎？'
			/>
			<div className='content'>
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to='.'>遊戲時間表</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						{id === 'new' && '新遊戲'}
						{gameQuery.data?.id &&
							`[${gameQuery.data.code}] ${gameQuery.data.title}`}
					</BreadcrumbItem>
				</Breadcrumb>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<input type='hidden' name='id' ref={register} />
					<Row form>
						<Col md={8}>
							<Nav tabs style={{ marginBottom: 20 }}>
								<NavItem>
									<NavLink
										className={classnames({
											active: tabIndex === TabIndex.CONFIG,
										})}
										onClick={() => {
											handleToggleTab(TabIndex.CONFIG)
										}}
									>
										基本設定
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink
										className={classnames({
											active: tabIndex === TabIndex.PLAYERS_AND_REWARDS,
										})}
										onClick={() => {
											handleToggleTab(TabIndex.PLAYERS_AND_REWARDS)
										}}
									>
										玩家＆獎勵 ({characterAndRewardsFieldArray.fields.length})
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink
										className={classnames({
											active: tabIndex === TabIndex.JOURNALS,
										})}
										onClick={() => {
											handleToggleTab(TabIndex.JOURNALS)
										}}
									>
										NPC＆世界紀錄 ({journalsFieldArray.fields.length})
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink
										className={classnames({
											active: tabIndex === TabIndex.OTHERS,
										})}
										onClick={() => {
											handleToggleTab(TabIndex.OTHERS)
										}}
									>
										其他
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={tabIndex}>
								<TabPane tabId={TabIndex.CONFIG}>
									<Card>
										<CardBody>
											<Row form>
												<Col xs={12} sm={12} md={12} lg={8}>
													<Row form>
														<Col xs={7}>
															<FormGroup>
																<Label>開始日期時間</Label>
																<Input
																	name='startAtDate'
																	type='date'
																	innerRef={register({})}
																	invalid={!!errors.startAtDate}
																	autoComplete='off'
																/>
																{errors.startAtDate && (
																	<FormFeedback>
																		{errors.startAtDate?.message}
																	</FormFeedback>
																)}
															</FormGroup>
														</Col>
														<Col xs={5}>
															<FormGroup>
																<Label>&nbsp;</Label>
																<Input
																	name='startAtTime'
																	type='time'
																	innerRef={register({
																		required: '必須填寫',
																	})}
																	step='900'
																	invalid={!!errors.startAtTime}
																	autoComplete='off'
																/>
																{errors.startAtTime && (
																	<FormFeedback>
																		{errors.startAtTime?.message}
																	</FormFeedback>
																)}
															</FormGroup>
														</Col>
													</Row>
												</Col>

												<Col xs={12} sm={12} md={12} lg={8}>
													<Row form>
														<Col xs={7}>
															<FormGroup>
																<Label>結束日期時間</Label>
																<Input
																	name='endAtDate'
																	type='date'
																	innerRef={register({})}
																	invalid={!!errors.endAtDate}
																	autoComplete='off'
																/>
																{errors.endAtDate && (
																	<FormFeedback>
																		{errors.endAtDate?.message}
																	</FormFeedback>
																)}
															</FormGroup>
														</Col>
														<Col xs={5}>
															<FormGroup>
																<Label>&nbsp;</Label>
																<Input
																	name='endAtTime'
																	type='time'
																	innerRef={register({
																		required: '必須填寫',
																	})}
																	step='900'
																	invalid={!!errors.endAtTime}
																	autoComplete='off'
																/>
																{errors.endAtTime && (
																	<FormFeedback>
																		{errors.endAtTime?.message}
																	</FormFeedback>
																)}
															</FormGroup>
														</Col>
													</Row>
												</Col>

												<Col xs={12} lg={7}>
													<FormGroup>
														<Label>城市 (店舖)</Label>
														<ReactHookFormController
															control={control}
															name='city'
															rules={{
																required: '必須選擇',
															}}
															render={(
																{ name, value, onChange },
																{ invalid }
															) => (
																<StyledReactSelect<City>
																	name={name}
																	placeholder='選擇……'
																	isLoading={citiesQuery.isLoading}
																	isSearchable
																	options={citiesQuery.data || []}
																	getOptionLabel={cityOptionLabel}
																	isOptionSelected={(item) =>
																		item.id === gameCodeComponents.city?.id
																	}
																	value={value}
																	onChange={onChange}
																	isInvalid={invalid}
																/>
															)}
														/>
														{errors.city && (
															<FormFeedback>
																{errors.city?.message}
															</FormFeedback>
														)}
													</FormGroup>
												</Col>
												<Col xs={12} lg={5}>
													<FormGroup>
														<Label>DM</Label>
														<ReactHookFormController
															control={control}
															name='dm'
															rules={{
																required: '必須選擇一位DM，沒DM還玩什麼鬼阿?!',
															}}
															render={(
																{ name, value, onChange },
																{ invalid }
															) => (
																<StyledReactSelect<User>
																	name={name}
																	placeholder='選擇……'
																	isLoading={dmsQuery.isLoading}
																	isSearchable
																	options={dmsQuery.data || []}
																	getOptionLabel={dmOptionLabel}
																	isOptionSelected={(item) =>
																		item.id === gameCodeComponents.dm?.id
																	}
																	value={value}
																	onChange={onChange}
																	isInvalid={invalid}
																/>
															)}
														/>
														{errors.dm && (
															<FormFeedback>{errors.dm?.message}</FormFeedback>
														)}
													</FormGroup>
												</Col>
											</Row>
										</CardBody>
									</Card>

									<Card>
										<CardBody>
											<FormGroup>
												<Label>劇本編號</Label>
												<Input
													name='code'
													placeholder={gameCodePlaceholder}
													type='text'
													innerRef={register()}
													invalid={!!errors.code}
													autoComplete='off'
												/>
												{errors.code && (
													<FormFeedback>{errors.code?.message}</FormFeedback>
												)}

												<FormText color='muted'>
													會按城市、DM、日期自動新成。也可自訂。
												</FormText>
											</FormGroup>

											<FormGroup>
												<Label>劇本名稱</Label>
												<Input
													name='title'
													placeholder='香炸冒險者'
													type='text'
													innerRef={register({
														required: '必須填寫劇本名稱',
													})}
													invalid={!!errors.title}
													autoComplete='off'
												/>
												{errors.title && (
													<FormFeedback>{errors.title?.message}</FormFeedback>
												)}
											</FormGroup>

											<FormGroup>
												<Label>世界觀向日期時間</Label>

												<InputGroup>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>第三紀元</InputGroupText>
													</InputGroupAddon>
													<Input
														name='worldStartAt'
														type='date'
														innerRef={register({
															required: '必須填寫',
														})}
														invalid={!!errors.worldStartAt}
														autoComplete='off'
													/>
												</InputGroup>
												{errors.worldStartAt && (
													<FormFeedback>
														{errors.worldStartAt?.message}
													</FormFeedback>
												)}
											</FormGroup>

											<Row form>
												<Col xs={5}>
													<FormGroup inline>
														<Label>等級範圍</Label>
														<Row
															form
															style={{
																justifyContent: 'flex-start',
																alignItems: 'center',
															}}
														>
															<Col>
																<Input
																	name='lvMin'
																	type='select'
																	innerRef={register({
																		required: '必須填寫',
																		valueAsNumber: true,
																	})}
																	invalid={!!errors.lvMin}
																	autoComplete='off'
																>
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																	<option>8</option>
																	<option>9</option>
																	<option>10</option>
																	<option>11</option>
																	<option>12</option>
																	<option>13</option>
																	<option>14</option>
																	<option>15</option>
																	<option>16</option>
																	<option>17</option>
																	<option>18</option>
																	<option>19</option>
																	<option>20</option>
																</Input>
															</Col>
															<FormText>至</FormText>
															<Col>
																<Input
																	name='lvMax'
																	type='select'
																	innerRef={register({
																		required: '必須填寫',
																		valueAsNumber: true,
																	})}
																	invalid={!!errors.lvMax}
																	autoComplete='off'
																>
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																	<option>8</option>
																	<option>9</option>
																	<option>10</option>
																	<option>11</option>
																	<option>12</option>
																	<option>13</option>
																	<option>14</option>
																	<option>15</option>
																	<option>16</option>
																	<option>17</option>
																	<option>18</option>
																	<option>19</option>
																	<option>20</option>
																</Input>
															</Col>
														</Row>
														{errors.lvMin && (
															<FormFeedback>
																{errors.lvMin?.message}
															</FormFeedback>
														)}
													</FormGroup>
												</Col>

												<Col xs={{ size: 5, offset: 1 }}>
													<FormGroup inline>
														<Label>玩家人數</Label>
														<Row
															form
															style={{
																justifyContent: 'flex-start',
																alignItems: 'center',
															}}
														>
															<Col>
																<Input
																	name='capacityMin'
																	type='select'
																	innerRef={register({
																		required: '必須填寫',
																		valueAsNumber: true,
																	})}
																	invalid={!!errors.capacityMin}
																	autoComplete='off'
																>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																	<option>8</option>
																</Input>
															</Col>
															<FormText>至</FormText>
															<Col>
																<Input
																	name='capacityMax'
																	type='select'
																	innerRef={register({
																		required: '必須填寫',
																		valueAsNumber: true,
																	})}
																	invalid={!!errors.capacityMax}
																	autoComplete='off'
																>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																	<option>8</option>
																</Input>
															</Col>
														</Row>
														{errors.lvMin && (
															<FormFeedback>
																{errors.lvMin?.message}
															</FormFeedback>
														)}
													</FormGroup>
												</Col>
											</Row>

											<FormGroup>
												<Label>劇本簡介</Label>
												<Input
													name='description'
													placeholder='有一群冒險者闖進了巨人的廚房，正巧牠正打算吃天婦羅……'
													type='textarea'
													rows={6}
													innerRef={register({
														required: '必須填寫',
													})}
													invalid={!!errors.description}
													autoComplete='off'
												/>
												{errors.description && (
													<FormFeedback>
														{errors.description?.message}
													</FormFeedback>
												)}
											</FormGroup>

											<FormGroup>
												<Label>備註</Label>
												<Input
													name='remark'
													type='textarea'
													rows={4}
													innerRef={register({})}
													invalid={!!errors.remark}
													autoComplete='off'
												/>
												{errors.remark && (
													<FormFeedback>{errors.remark?.message}</FormFeedback>
												)}
											</FormGroup>

											<FormGroup>
												<Label>標籤</Label>
												<Input
													name='tags'
													type='text'
													innerRef={register({})}
													invalid={!!errors.tags}
													autoComplete='off'
												/>
												{errors.tags && (
													<FormFeedback>{errors.tags?.message}</FormFeedback>
												)}
												<FormText color='muted'>
													方便用來搜尋。用逗號(,)隔開每個詞語。
												</FormText>
											</FormGroup>
										</CardBody>
									</Card>
								</TabPane>

								<TabPane tabId={TabIndex.PLAYERS_AND_REWARDS}>
									{characterAndRewardsFieldArray.fields.map(
										(characterAndReward, i) => (
											<Card key={characterAndReward.faid}>
												<CardBody>
													<Row>
														<Col xs={6}>
															<input
																type='hidden'
																name={`characterAndRewards[${i}].id`}
																defaultValue={characterAndReward.id}
																ref={register}
															/>
															<FormGroup>
																<Label>玩家角色</Label>
																<ReactHookFormController
																	control={control}
																	name={`characterAndRewards[${i}].character`}
																	defaultValue={characterAndReward.character}
																	rules={{
																		required: '必須選擇',
																	}}
																	render={(
																		{ name, value, onChange },
																		{ invalid }
																	) => (
																		<StyledReactAsyncSelect<Character>
																			name={name}
																			placeholder='輸入角色名稱/編號'
																			isSearchable
																			loadOptions={
																				handleAsyncSelectLoadCharacters
																			}
																			getOptionLabel={characterOptionLabel}
																			isOptionSelected={() => false}
																			loadOptionsDebounce={800}
																			value={value}
																			onChange={onChange}
																			isInvalid={invalid}
																		/>
																	)}
																/>

																{errors.characterAndRewards &&
																	!!errors.characterAndRewards[i]
																		?.character && (
																		<FormFeedback>
																			{errors.characterAndRewards &&
																				errors.characterAndRewards[i]?.character
																					?.message}
																		</FormFeedback>
																	)}
															</FormGroup>
														</Col>
														<Col xs={6} className='text-right'>
															<Button
																type='button'
																color='light'
																onClick={() => {
																	handleClickCloneCharacterAndReward(i)
																}}
																tabIndex={-1}
															>
																複製
															</Button>{' '}
															<Button
																type='button'
																color='light'
																onClick={() => {
																	handleClickRemoveCharacterAndReward(i)
																}}
																tabIndex={-1}
															>
																刪除
															</Button>
														</Col>
													</Row>

													<Row>
														<Col xs={4}>
															<FormGroup>
																<Label>XP (經驗值)</Label>
																<Input
																	name={`characterAndRewards[${i}].xp`}
																	type='number'
																	innerRef={register({
																		required:
																			'必須填寫啦，不填對玩家太可憐吧！',
																		valueAsNumber: true,
																	})}
																	defaultValue={characterAndReward.xp}
																	invalid={
																		errors.characterAndRewards &&
																		!!errors.characterAndRewards[i]?.xp
																	}
																	autoComplete='off'
																/>
																{errors.characterAndRewards &&
																	!!errors.characterAndRewards[i]?.xp && (
																		<FormFeedback>
																			{errors.characterAndRewards &&
																				errors.characterAndRewards[i]?.xp
																					?.message}
																		</FormFeedback>
																	)}
															</FormGroup>
														</Col>
														<Col xs={4}>
															<FormGroup>
																<Label>GP (金錢)</Label>
																<Input
																	name={`characterAndRewards[${i}].gp`}
																	type='number'
																	innerRef={register({
																		required: '必須填寫。',
																		valueAsNumber: true,
																	})}
																	defaultValue={characterAndReward.gp}
																	invalid={
																		errors.characterAndRewards &&
																		!!errors.characterAndRewards[i]?.gp
																	}
																	autoComplete='off'
																/>
																{errors.characterAndRewards &&
																	!!errors.characterAndRewards[i]?.gp && (
																		<FormFeedback>
																			{errors.characterAndRewards &&
																				errors.characterAndRewards[i]?.gp
																					?.message}
																		</FormFeedback>
																	)}
																<FormText color='muted'>
																	可填小數，例如要派發1sp，請填寫0.1；1cp是0.01。
																</FormText>
															</FormGroup>
														</Col>
													</Row>

													<Row>
														<Col xs={6}>
															<FormGroup>
																<Label>其他獎勵/物品/稱號</Label>
																<Input
																	name={`characterAndRewards[${i}].items`}
																	type='textarea'
																	rows={4}
																	innerRef={register()}
																	defaultValue={characterAndReward.items}
																	invalid={
																		errors.characterAndRewards &&
																		!!errors.characterAndRewards[i]?.items
																	}
																	autoComplete='off'
																/>
																{errors.characterAndRewards &&
																	!!errors.characterAndRewards[i]?.items && (
																		<FormFeedback>
																			{errors.characterAndRewards &&
																				errors.characterAndRewards[i]?.items
																					?.message}
																		</FormFeedback>
																	)}
																<FormText color='muted'>
																	會放進角色的物品欄裡，一行一個。
																</FormText>
															</FormGroup>
														</Col>

														<Col xs={6}>
															<FormGroup>
																<Label>備註</Label>
																<Input
																	name={`characterAndRewards[${i}].remark`}
																	type='textarea'
																	rows={4}
																	innerRef={register()}
																	defaultValue={characterAndReward.remark}
																	invalid={
																		errors.characterAndRewards &&
																		!!errors.characterAndRewards[i]?.remark
																	}
																	autoComplete='off'
																/>
																{errors.characterAndRewards &&
																	!!errors.characterAndRewards[i]?.remark && (
																		<FormFeedback>
																			{errors.characterAndRewards &&
																				errors.characterAndRewards[i]?.remark
																					?.message}
																		</FormFeedback>
																	)}
																<FormText color='muted'>
																	可寫下角色的變化、與NPC好感度變化、名聲變化……
																</FormText>
															</FormGroup>
														</Col>
													</Row>
												</CardBody>
											</Card>
										)
									)}
									<div className='text-center'>
										<Button
											type='button'
											onClick={handleClickAddCharacterAndReward}
										>
											新增玩家
										</Button>
									</div>
								</TabPane>

								<TabPane tabId={TabIndex.JOURNALS}>
									{journalsFieldArray.fields.map((journal, i) => (
										<Card key={journal.faid}>
											<CardBody>
												<Row>
													<Col xs={6}>
														<input
															type='hidden'
															name={`journals[${i}].id`}
															defaultValue={journal.id}
															ref={register}
														/>
														<FormGroup>
															<Label>NPC角色/組織/地點/etc.</Label>
															<Input
																name={`journals[${i}].subject`}
																type='text'
																innerRef={register()}
																defaultValue={journal.subject}
																invalid={
																	errors.journals &&
																	!!errors.journals[i]?.subject
																}
																autoComplete='off'
															/>
															{errors.journals &&
																!!errors.journals[i]?.subject && (
																	<FormFeedback>
																		{errors.journals &&
																			errors.journals[i]?.subject?.message}
																	</FormFeedback>
																)}
															<FormText color='muted'>
																紀錄的對象，可留空，默認為「世界」。
															</FormText>
														</FormGroup>
													</Col>
													<Col xs={6} className='text-right'>
														<Button
															type='button'
															color='light'
															onClick={() => {
																handleClickCloneJournal(i)
															}}
															tabIndex={-1}
														>
															複製
														</Button>{' '}
														<Button
															type='button'
															color='light'
															onClick={() => {
																handleClickRemoveJournal(i)
															}}
															tabIndex={-1}
														>
															刪除
														</Button>
													</Col>
												</Row>

												<FormGroup>
													<Label>描述</Label>
													<Input
														name={`journals[${i}].description`}
														type='textarea'
														rows={4}
														innerRef={register({
															required: '必須填寫',
														})}
														defaultValue={journal.description}
														invalid={
															errors.journals &&
															!!errors.journals[i]?.description
														}
														autoComplete='off'
													/>
													{errors.journals &&
														!!errors.journals[i]?.description && (
															<FormFeedback>
																{errors.journals &&
																	errors.journals[i]?.description?.message}
															</FormFeedback>
														)}
												</FormGroup>
											</CardBody>
										</Card>
									))}
									<div className='text-center'>
										<Button type='button' onClick={handleClickAddJournal}>
											新增紀錄
										</Button>
									</div>
								</TabPane>
							</TabContent>
						</Col>
						<Col md={4}>
							{gameStatus !== 'completed' && (
								<>
									<Button
										type='submit'
										color='success'
										block
										isLoading={gameMutation.isLoading}
									>
										儲存修改
									</Button>
									<hr />
								</>
							)}
							{gameStatus === 'draft' && (
								<>
									<HelperBox color='warning'>
										<p>
											這場遊戲目前是 <strong>草稿</strong>
											，<br />
											玩家看不見這場遊戲，也沒法報名。
										</p>
										<p>你可以儲存修改後「發佈」</p>
										<Button
											type='button'
											color='primary'
											block
											disabled={id === 'new' || formState.isDirty}
											onClick={handleClickPrePublish}
										>
											發佈
										</Button>
									</HelperBox>
								</>
							)}

							{gameStatus === 'published' && (
								<>
									<HelperBox color='success'>
										<p>
											這場遊戲目前 <strong>已發佈</strong>
											，<br />
											玩家能搜尋到這場遊戲、能報名、會顯示在行事曆上。
										</p>

										<p>
											完成遊戲後，請填好世界觀結束時間、「玩家＆獎勵」及「NPC＆世界紀錄」，接著「完成遊戲」。
										</p>

										<FormGroup>
											<Label>世界觀向結束日期</Label>

											<InputGroup>
												<InputGroupAddon addonType='prepend'>
													<InputGroupText>第三紀元</InputGroupText>
												</InputGroupAddon>
												<Input
													name='worldEndAt'
													type='date'
													innerRef={register({
														required: '必須填寫',
													})}
													invalid={!!errors.worldEndAt}
													autoComplete='off'
												/>
											</InputGroup>
											{errors.worldEndAt && (
												<FormFeedback>
													{errors.worldEndAt?.message}
												</FormFeedback>
											)}
										</FormGroup>

										<Button
											type='button'
											color='success'
											block
											onClick={handleClickPreComplete}
											disabled={formState.isDirty}
										>
											完成遊戲
										</Button>

										<hr />

										<p>如有必要，你可「改回草稿狀態」</p>
										<Button
											type='button'
											color='light'
											outline
											block
											onClick={handleClickPreDraft}
											disabled={formState.isDirty}
										>
											改回草稿狀態
										</Button>
									</HelperBox>
								</>
							)}

							{gameStatus === 'completed' && (
								<>
									<HelperBox color='disabled'>
										<p>
											這場遊戲 <strong>已完成</strong>
											，<br />
											獎勵已經派發，紀錄也已確認，你不能修改任何資料。
										</p>
									</HelperBox>
								</>
							)}

							<Button
								type='button'
								color='default'
								onClick={handleTogglePromoTextModal}
								block
								outline
							>
								宣傳／報名文字
							</Button>

							{gameQuery.data?.googleCalendarEventUrl && (
								<a
									className='btn btn-outline-default btn-block'
									href={gameQuery.data?.googleCalendarEventUrl}
									target='_blank'
								>
									GOOGLE 行事曆
								</a>
							)}
						</Col>
					</Row>
				</Form>

				<Modal isOpen={isPublishModalOpened} toggle={handleTogglePublishModal}>
					<ModalHeader toggle={handleTogglePublishModal}>發佈遊戲</ModalHeader>
					<ModalBody>
						<p>當你點下「發佈遊戲」後：</p>
						<ul>
							<li>遊戲會變成「已發佈」狀態；</li>
							<li>玩家能夠搜尋到這場遊戲；</li>
							<li>玩家會收到通知；</li>
							<li>玩家能夠報名這場遊戲；</li>
							<li>遊戲會出現在行事曆上。</li>
						</ul>
						<p>請再三確認資料均已填妥。在發佈後你可隨時改回「草稿」狀態。</p>
					</ModalBody>
					<ModalFooter>
						<Button
							color='primary'
							onClick={handleClickConfirmPublish}
							isLoading={gameMutation.isLoading}
						>
							發佈遊戲
						</Button>{' '}
						<Button color='secondary' onClick={handleTogglePublishModal}>
							取消
						</Button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={isDraftModalOpened} toggle={handleToggleDraftModal}>
					<ModalHeader toggle={handleToggleDraftModal}>轉回草稿</ModalHeader>
					<ModalBody>
						<p>當你點下「回到草稿」後：</p>
						<ul>
							<li>遊戲會變成「草稿」狀態；</li>
							<li>玩家不能報名這場遊戲；</li>
							<li>已有報名會保留；</li>
							<li>遊戲會從行事曆上刪去。</li>
						</ul>
					</ModalBody>
					<ModalFooter>
						<Button
							color='primary'
							onClick={handleClickConfirmDraft}
							isLoading={gameMutation.isLoading}
						>
							回到草稿
						</Button>{' '}
						<Button color='secondary' onClick={handleToggleDraftModal}>
							取消
						</Button>
					</ModalFooter>
				</Modal>

				<Modal
					isOpen={isCompleteModalOpened}
					toggle={handleToggleCompleteModal}
				>
					<ModalHeader toggle={handleToggleCompleteModal}>
						完成遊戲！
					</ModalHeader>
					<ModalBody>
						<p>開game辛苦啦！現在準備好派發獎勵了嗎？在點下「完成遊戲」後：</p>
						<ul>
							<li>遊戲會變成「已完成」狀態；</li>
							<li>
								系統會自動派發寫在「玩家＆獎勵」的獎勵，且出現在角色的活動歷程中。
							</li>
							<li>你將不能修改任何資料。</li>
							<li>假如真的有任何出錯而想修改，請聯絡IT部。</li>
						</ul>
					</ModalBody>
					<ModalFooter>
						<Button
							color='primary'
							onClick={handleClickConfirmComplete}
							isLoading={gameMutation.isLoading}
						>
							完成遊戲
						</Button>{' '}
						<Button color='secondary' onClick={handleToggleCompleteModal}>
							取消
						</Button>
					</ModalFooter>
				</Modal>

				<Modal
					isOpen={isPromoTextModalOpened}
					toggle={handleTogglePromoTextModal}
				>
					<ModalHeader toggle={handleTogglePromoTextModal}>
						宣傳／報名文字
					</ModalHeader>
					<ModalBody>
						<pre
							style={{
								whiteSpace: 'pre-wrap',
								backgroundColor: 'rgba(0, 0, 0, 0.05)',
								padding: '1em',
							}}
						>
							{promoText}
						</pre>
						<Button onClick={handleCopyPromoText} outline block color='default'>
							複製文字
						</Button>
					</ModalBody>
					<ModalFooter>
						<Button color='secondary' onClick={handleTogglePromoTextModal}>
							關閉
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		</>
	)
}

export default GameEditor
