import React from 'react'

const underConstruction = '/assets/images/under-construction.png'

const UnderConstruction = () => (
	<div className='content'>
		<img src={underConstruction} />
	</div>
)

export default React.memo(UnderConstruction)
