import React from 'react'
import classnames from 'classnames'

import 'rpg-awesome/css/rpg-awesome.min.css'
import { RPGIconName } from './RPGIconName'

type Props = React.HTMLAttributes<HTMLElement> & {
	icon: RPGIconName
	fixWidth?: boolean
	size?: 'normal' | 'lg' | '2x' | '3x' | '4x' | '5x'
}

const RPGIcon = ({
	icon,
	fixWidth = true,
	size = 'normal',
	className,
	...others
}: Props) => (
	<i
		className={classnames(
			'ra',
			`ra-${icon}`,
			`ra-${size}`,
			{
				'ra-fw': fixWidth,
			},
			className
		)}
		{...others}
	/>
)

export default React.memo(RPGIcon)
