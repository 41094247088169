import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { CharacterAttachable } from '../../types/character.type'
import { Ruleset } from '../../types/ruleset.type'

import {
	getRuleset_DnD5e,
	getRulesetAttachable,
} from '../../helpers/api.helper'

import CBContext from './CBContext'

const CBRulesets = (): JSX.Element => {
	const [selectedItem, setSelectedItem] = useState<Ruleset | null>(null)

	const rulesetsQuery = useQuery<Ruleset[]>(
		['rulesets'],
		() => getRuleset_DnD5e().then((ruleset) => [ruleset]),
		{
			select: (rulesets) => {
				setSelectedItem(rulesets && rulesets.length > 0 ? rulesets[0] : null)
				return rulesets
			},
		}
	)

	if (rulesetsQuery.isLoading) {
		return <></>
	}

	if (rulesetsQuery.isError) {
		return <div>無法讀取規則！</div>
	}

	return (
		<>
			<div>{selectedItem && <CBRulesetDetail ruleset={selectedItem} />}</div>
		</>
	)
}

const CBRulesetDetail = ({ ruleset }: { ruleset: Ruleset }) => {
	const { setContext } = useContext(CBContext)

	const attachableQuery = useQuery<CharacterAttachable>(
		['ruleset', ruleset.id, 'attachable'],
		() => getRulesetAttachable(ruleset.id),
		{
			enabled: !!ruleset,
		}
	)

	useEffect(() => {
		if (setContext && ruleset && attachableQuery.data) {
			setContext((prev) => ({
				...prev,
				ruleset,
				rulesetAttachableOAO: { origin: attachableQuery.data },
			}))
		}
	}, [setContext, ruleset, attachableQuery.data])

	if (attachableQuery.isLoading) {
		return <></>
	}

	if (attachableQuery.isError) {
		return <div>無法讀取規則Attachable！</div>
	}

	return <></>
}

export default CBRulesets
