import React from 'react'
import { components as reactSelectComponents } from 'react-select'
import styled from 'styled-components'

import Icon from '../Icon/Icon'
import StyledReactAsyncSelect, {
	Props as SRASProps,
} from '../Select/StyledReactAsyncSelect'

const StyleSRAS = styled(StyledReactAsyncSelect)`
	min-width: 20em;
`
const StyledSearch = styled(Icon)`
	margin-top: -5px;
	margin-left: 2px;
	margin-right: 3px;
`

const DropdownIndicator = (props: any) => (
	<reactSelectComponents.DropdownIndicator {...props}>
		<StyledSearch category='bi' icon='search' />
	</reactSelectComponents.DropdownIndicator>
)

const _components = {
	DropdownIndicator,
}

type Props<T> = SRASProps<T>

const SearchInput: <T>(props: Props<T>) => JSX.Element = ({
	components,
	...others
}) => (
	<StyleSRAS
		components={{
			..._components,
			...components,
		}}
		{...others}
	/>
)

export default SearchInput
