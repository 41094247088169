import React, { useMemo } from 'react'

import {
	CharacterAttachable,
	CharacterAttachableDTO,
	CharacterFeatureDTO,
	CharacterFullDTO,
	CharacterMutationDTO,
	CharacterValueDTO,
} from '../types/character.type'

import * as mathjs from 'mathjs'
import * as util from 'util'

const rule: CharacterAttachable = {
	id: 'dnd',
	name: 'DND規則',
	category: 'rule:dnd',
	code: 'rule:dnd',
	features: [
		{
			name: 'stat',
			code: 'rule:dnd5e:stat',
			isHidden: true,
			mutations: [
				{
					key: 'stat_$any_score',
					formula: '10',
				},
				{
					key: 'stat_$any_modifier',
					formula: 'floor((stat_$any_score-10)/2)',
				},
				{
					key: 'stat_$any_save',
					formula: 'stat_$any_modifier',
				},
			],
		},
		{
			name: 'proficiencyBonus',
			code: 'rule:dnd5e:proficiencyBonus',
			isHidden: true,
			mutations: [
				{
					key: 'proficiencyBonus',
					formula: 'floor((level-1)/4+2)',
				},
			],
		},
		{
			name: 'skill',
			code: 'rule:dnd5e:skills',
			isHidden: true,
			mutations: [
				{
					key: 'skill_$any_modifier',
					formula: '+(skill_$any_proficiencyRatio * proficiencyBonus)',
				},
			],
		},
		{
			name: 'hp',
			code: 'rule:dnd5e:hp',
			isHidden: true,
			mutations: [
				{
					key: 'hp_max',
					formula: 'stat_constitution_modifier * level',
				},
			],
		},
	],
}

const custom: CharacterAttachable = {
	id: 'custom',
	name: '自定義',
	category: 'userdefined',
	code: 'userdefined:custom',
	features: [
		{
			name: '配點',
			code: 'userdefined:custom:stat',
			isHidden: true,
			mutations: [
				{
					key: 'stat_strength_score',
					formula: '15',
				},
				{
					key: 'stat_dexterity_score',
					formula: '12',
				},
				{
					key: 'stat_constitution_score',
					formula: '15',
				},
				{
					key: 'stat_intelligence_score',
					formula: '10',
				},
				{
					key: 'stat_wisdom_score',
					formula: '10',
				},
				{
					key: 'stat_charisma_score',
					formula: '12',
				},
			],
		},
	],
}

const race: CharacterAttachable = {
	id: 'Half-Elf',
	name: 'Half-Elf',
	category: 'race',
	code: 'race:half-elf',
	features: [
		{
			name: '黑暗視覺',
			code: 'race:hillDwarf:darkvision',
			description:
				'習慣了地底的生活，你在黑暗與微光環境下仍能保持卓越視覺。你在看距離你60呎範圍內的事物時，微光的照明程度對你而言視作明亮，黑暗則視作微光。 你無法辨別黑暗中的顏色，而只能看到灰黑的輪廓。',
			mutations: [
				{
					key: 'sense_darkvision',
					formula: '60',
				},
			],
		},
		{
			name: '陣營',
			code: 'race:hillDwarf:alignment',
			description:
				'大部分的矮人是守序的，堅信著井然有序的社會所帶來的好處。他們也傾向善良，有著強烈的公平競爭意識，並認為每個人都應該共享公正秩序的益處。',
		},
		{
			name: '體型',
			code: 'race:hillDwarf:size',
			description:
				'矮人的身高介於4到5呎高，且平均大約150磅重。你的體型為中型。',
			mutations: [
				{
					key: 'size',
					formula: '"M"',
				},
			],
		},
		{
			name: '移動速度',
			code: 'race:hillDwarf:speed',
			description: '你的移動速度是25尺，且並不會因為穿著重甲而減少。',
			mutations: [
				{
					key: 'speed_walking',
					formula: '25',
				},
			],
		},
		{
			name: '矮人戰鬥訓練',
			code: 'race:hillDwarf:dwarvenCombatTraining',
			description: '你熟練於戰斧、手斧、輕錘、和戰錘。',
			mutations: [
				{
					key: 'proficiency_weapons',
					formula: '+"戰斧"',
				},
				{
					key: 'proficiency_weapons',
					formula: '+"手斧"',
				},
				{
					key: 'proficiency_weapons',
					formula: '+"輕錘"',
				},
				{
					key: 'proficiency_weapons',
					formula: '+"戰錘"',
				},
			],
		},
		{
			name: '矮人韌性',
			code: 'race:hillDwarf:dwarvenResilience',
			description: '你在對抗毒素的豁免檢定中具有優勢，且你對毒素傷害具有抗性。',
		},
		{
			name: '岩石熟悉',
			code: 'race:hillDwarf:stonecunning',
			description:
				'每當你進行關聯於石製品起源的智力（歷史）檢定時，你被視為熟練於歷史技能，並將你二倍的熟練加值代替你原本的熟練加值加入該檢定中。',
			type: 'triggered',
			mutations: [
				{
					key: 'skill_history_proficiencyRatio',
					formula: '2',
				},
			],
		},
		{
			name: '額外資料',
			code: 'race:hillDwarf:metadata',
			isHidden: true,
			mutations: [
				{
					key: 'metadata_race_name',
					formula: '"丘陵矮人"',
				},
				{
					key: 'metadata_race_code',
					formula: '"race:hillDwarf"',
				},
			],
		},
		{
			name: '能力值',
			code: 'race:hillDwarf:stat',
			description: '你的體質+2、感知+1',
			mutations: [
				{
					key: 'stat_constitution_score',
					formula: '+2',
				},
				{
					key: 'stat_wisdom_score',
					formula: '+1',
				},
			],
		},
		{
			name: '語言',
			code: 'race:hillDwarf:languages',
			description:
				'你能夠說、讀、寫通用語和矮人語。矮人語充斥了堅硬的輔音和喉音，而這些特徵也將顯露在任何矮人可能會說的其他語言中。',
			mutations: [
				{
					key: 'proficiency_languages',
					formula: '+"通用語"',
				},
				{
					key: 'proficiency_languages',
					formula: '+"矮人語"',
				},
			],
		},
		{
			name: '矮人耐力',
			code: 'race:hillDwarf:dwarvenToughness',
			description: '你的生命值最大值增加1點，且在每次你提升等級時再增加1點。',
			mutations: [
				{
					key: 'hp_max',
					formula: '+level',
				},
			],
		},
		{
			name: '工具熟練',
			code: 'race:hillDwarf:toolProficiency',
			description:
				'你熟練於下列其中一個你所選擇的工匠工具：鐵匠工具、釀酒師設備、或石匠工具。',
			amount: 1,
			options: [
				{
					name: '工具熟練-鐵匠工具',
					code: 'race:hillDwarf:toolProficiency:smithsTool',
					description: '你熟練於鐵匠工具。',
					mutations: [
						{
							key: 'proficiency_tools',
							formula: '+"鐵匠工具"',
						},
					],
				},
				{
					name: '工具熟練-釀酒師設備',
					code: 'race:hillDwarf:toolProficiency:brewersSupply',
					description: '你熟練於釀酒師設備。',
					mutations: [
						{
							key: 'proficiency_tools',
							formula: '+"釀酒師設備"',
						},
					],
				},
				{
					name: '工具熟練-石匠工具',
					code: 'race:hillDwarf:toolProficiency:masonsTool',
					description: '你熟練於石匠工具。',
					mutations: [
						{
							key: 'proficiency_tools',
							formula: '+"石匠工具"',
						},
					],
				},
			],
		},
		{
			name: '年齡',
			code: 'race:hillDwarf:age',
			description:
				'矮人的成熟速度和人類相同，但他們直到50歲之前都會被視作未成年。他們平均可以活大約350年。',
		},
	],
}

const fighterLv2: CharacterAttachable = {
	id: 'fighter2',
	name: '戰士(等級2)',
	category: 'cls',
	code: 'cls:fighter2',
	features: [
		{
			name: '額外資料',
			code: 'cls:barbarian1:metadata',
			isHidden: true,
			mutations: [
				{
					key: 'metadata_cls_name',
					formula: '"野蠻人1"',
				},
				{
					key: 'metadata_cls_code',
					formula: '"cls:barbarian1"',
				},
			],
		},
		{
			name: '生命值／生命骰',
			code: 'cls:barbarian1:hp',
			description: '你的生命上限+12，且獲得一顆d12生命骰',
			mutations: [
				{
					key: 'hp_max',
					formula: '+12',
				},
				{
					key: 'hitDice_d12_max',
					formula: '+1',
				},
			],
		},
		{
			name: '武器熟練',
			code: 'cls:barbarian1:weapons',
			description: '你熟練於簡易武器、軍用武器',
			mutations: [
				{
					key: 'proficiency_weapons',
					formula: '+"簡易武器"',
				},
				{
					key: 'proficiency_weapons',
					formula: '+"軍用武器"',
				},
			],
		},
		{
			name: '護甲熟練',
			code: 'cls:barbarian1:armors',
			description: '你熟練於輕甲、中甲、盾牌',
			mutations: [
				{
					key: 'proficiency_armors',
					formula: '+"輕甲"',
				},
				{
					key: 'proficiency_armors',
					formula: '+"中甲"',
				},
				{
					key: 'proficiency_armors',
					formula: '+"盾牌"',
				},
			],
		},
		{
			name: '豁免熟練',
			code: 'cls:barbarian1:savingThrows',
			description: '你熟練於力量豁免、體質豁免',
			mutations: [
				{
					key: 'savingThrow_strength_proficiencyRatio',
					formula: '1',
				},
				{
					key: 'savingThrow_constitution_proficiencyRatio',
					formula: '1',
				},
			],
		},
		{
			name: '技能熟練',
			code: 'cls:barbarian1:skills',
			description: '從馴養動物，運動，威嚇，自然，觀察和生存中選擇兩項',
			amount: 2,
			canRepeat: false,
			options: [
				{
					mutations: [
						{
							key: 'skill_animalHandling_proficiencyRatio',
							formula: '1',
						},
					],
				},
				{
					mutations: [
						{
							key: 'skill_athletic_proficiencyRatio',
							formula: '1',
						},
					],
				},
				{
					mutations: [
						{
							key: 'skill_intimidation_proficiencyRatio',
							formula: '1',
						},
					],
				},
				{
					mutations: [
						{
							key: 'skill_nature_proficiencyRatio',
							formula: '1',
						},
					],
				},
				{
					mutations: [
						{
							key: 'skill_perception_proficiencyRatio',
							formula: '1',
						},
					],
				},
				{
					mutations: [
						{
							key: 'skill_survival_proficiencyRatio',
							formula: '1',
						},
					],
				},
			],
		},
		{
			name: '狂暴',
			code: 'cls:barbarian1:rage',
			description:
				'你帶著原初的狂怒作戰。在你的回合內，你可以使用一個附贈動作進入狂暴。\n在狂暴中，只要你未穿著重甲，就獲得以下好處：\n* 你在力量檢定和力量豁免上具有優勢。\n* 當你進行使用力量的近戰武器攻擊時，你在傷害骰上獲得加值。此加值隨著你的野蠻人等級提升，見野蠻人表格中「狂暴傷害」一欄。\n* 你對鈍擊，穿刺和揮砍傷害具有抗性。\n如果你有施法能力，你無法在狂暴下使用法術或專注法術。\n你的狂暴持續１分鐘。如果你陷入昏迷，或是從你上一回合到你這一回合結束都未攻擊任何敵對生物，也未受到傷害，則狂暴提前結束。你也可以在你的回合使用一個附贈動作終止狂暴。\n根據你的野蠻人等級，野蠻人表格中給出了狂暴次數。如果你用光了這些次數，則必須進行一次長休息才能再次使用狂暴。',
		},
		{
			name: '無甲防御',
			code: 'cls:barbarian1:unarmoredDefense',
			description:
				'當你未穿著任何盔甲時，你的ＡＣ等於１０＋你的敏捷調整值＋你的體魄調整值。使用盾牌不會影響你從此能力獲益。',
			mutations: [
				{
					key: 'ac',
					formula: '10+stat_dexterity_modifier+stat_constitution_modifier',
				},
			],
		},
	],
}

const feat: CharacterAttachable = {
	id: 'moreHp',
	name: '血牛',
	category: 'feat',
	code: 'feat:moreHp',
	features: [
		{
			name: '額外資料',
			code: 'feat:moreHp:metadata',
			isHidden: true,
			mutations: [
				{
					key: 'metadata_feats',
					formula: '+"血牛"',
				},
			],
		},
		{
			name: '增加血量',
			code: 'feat:moreHp',
			mutations: [
				{
					key: 'hp_max',
					formula: '+2*stat_constitution_modifier',
				},
			],
		},
	],
}

const character: CharacterFullDTO = {
	id: 'karlott',
	name: '卡洛特',

	// clses: [
	// 	{
	// 		id: 'fighter',
	// 		name: '戰士',
	// 		level: 9,
	// 	},
	// ],
	// background: {
	// 	id: 'outlander',
	// 	name: '遠行者',
	// },
	attachables: [],
	features: [],
	mutations: [],
	property: {},
	propertyDetail: {},
}

character.features = []
character.mutations = []
;[rule, custom, race, fighterLv2, feat].forEach((attachable) => {
	const newAttachable: CharacterAttachableDTO = {
		...attachable,
		features: [],
	}

	attachable.features.forEach((feature) => {
		if (feature.options && Array.isArray(feature.options)) {
			const _mutations = []
			for (
				let i = 0;
				i < (feature.options?.length || 0) && i < (feature.amount || 1);
				i++
			) {
				_mutations.push(...(feature.options?.[i].mutations || []))
				feature = {
					...feature,
					...feature.options?.[i],
				}
			}
			feature.mutations = _mutations
			feature.options = []
		}

		const { mutations, type, ...featureOthers } = feature

		const newFeature: CharacterFeatureDTO = {
			...featureOthers,
			type: type || 'static',
			attachable: newAttachable,
			mutations: [],
			isActive: type !== 'triggered' && type !== 'activated',
		}

		;(mutations || []).forEach((mutation) => {
			const { type, ...mutationOthers } = mutation

			const newMutation: CharacterMutationDTO = {
				...mutationOthers,
				type: type || 'permanent',
				attachable: newAttachable,
				feature: newFeature,
			}

			newFeature.mutations.push(newMutation)
		})

		newAttachable.features.push(newFeature)
	})

	character.attachables.push(newAttachable)
	newAttachable.features.forEach((feature) => character.features.push(feature))
	character.mutations.push(
		...([] as CharacterMutationDTO[]).concat(
			...newAttachable.features.map((f) => f.mutations)
		)
	)
})

// compile keys' formulas
character.property = {}
character.propertyDetail = {}
const wildcardKeys = []

for (const m of character.mutations) {
	if (character.propertyDetail[m.key]) {
		continue
	}

	if (m.key.indexOf('$any') !== -1) {
		wildcardKeys.push(m.key)
	}

	const relatedMutations = character.mutations.filter((rM) => rM.key === m.key)

	let constant: string | undefined = '0'
	const changers = []
	let isArray = false
	let overallFormula = '0'

	if (relatedMutations.length > 0) {
		const { formula } = relatedMutations[0]
		const op = formula[0]
		const node = mathjs.parse(
			op === '+' || op === '-' ? formula.substr(1) : formula
		)
		if (node.isConstantNode && isNaN(parseInt(node.value))) {
			isArray = true
			constant = undefined
		}
	}

	for (const rM of relatedMutations) {
		const { formula } = rM
		if (['+', '-', '*', '/'].indexOf(formula[0]) !== -1) {
			if (isArray) {
				const op = formula[0]
				const result = formula.substr(1)
				const index = changers.indexOf(result)

				if (op === '+' && index === -1) {
					changers.push(result)
				} else if (op === '-' && index !== -1) {
					changers.splice(index, 1)
				}
			} else {
				changers.push(formula)
			}
		} else {
			constant = formula
		}
	}

	if (isArray) {
		if (changers.length === 0) {
			overallFormula = constant || ''
		} else {
			if (constant) {
				changers.unshift(constant)
			}
			overallFormula = JSON.stringify(changers)
		}
	} else {
		overallFormula = changers.reduce(
			(prev, curr) => `(${prev}${curr})`,
			constant || ''
		)
	}

	const dependencies: string[] = []
	mathjs.parse(overallFormula).traverse((n, _, parent) => {
		if (
			n.isSymbolNode &&
			(!parent?.isFunctionNode || (parent?.fn as unknown) !== n)
		) {
			dependencies.push(n.name as string)
		}
	})

	character.propertyDetail[m.key] = {
		key: m.key,
		formula: overallFormula,
		value: 0,
		dependencies,
		mutations: relatedMutations,
	}
}

// handle wildcard keys
for (const wcKey of wildcardKeys) {
	const detail = character.propertyDetail[wcKey]

	const anyReplacements: string[] = []
	const deps = detail.dependencies.filter((dep) => dep.indexOf('$any'))

	for (const dep of deps) {
		const pattern = new RegExp(dep.replace(/\$any/g, '(.+)'))
		for (const detailKey in character.propertyDetail) {
			const m = pattern.exec(detailKey)
			if (
				m &&
				m.length >= 2 &&
				m[1] !== '$any' &&
				anyReplacements.indexOf(m[1])
			) {
				anyReplacements.push(m[1])
			}
		}
	}

	for (const anyReplacement of anyReplacements) {
		const newKey = detail.key.replace(/\$any/g, anyReplacement)
		const newDetail: CharacterValueDTO = {
			key: newKey,
			formula: detail.formula.replace(/\$any/g, anyReplacement),
			dependencies: detail.dependencies.map((dep) =>
				dep.replace(/\$any/g, anyReplacement)
			),
			mutations: character.mutations.filter(
				(m) => m.key === detail.key || m.key === newKey
			),
			value: 0,
		}

		character.propertyDetail[newKey] = newDetail
	}
}

// const recalculateAll = (character: CharacterFullDTO) => {
// 	const newProperty: Record<string, CharacterValue> = {}

// 	for (const mutation of character.mutations) {
// 	}
// }

const parser = mathjs.parser()

const reevaluateByKey = (key: string, path: string[]) => {
	if (path.indexOf(key) !== -1) {
		return
	}

	if (parser.get(key) == undefined) {
		parser.set(key, 0)
		character.property[key] = 0
	}

	const detail = character.propertyDetail[key]

	if (detail) {
		for (const dep of detail.dependencies) {
			if (parser.get(dep) == undefined) {
				parser.set(dep, 0)
				character.property[dep] = 0
			}
		}

		const result = parser.evaluate(`${detail.formula}`)
		const resultValue =
			result._data?.map((item: unknown) => {
				if (typeof item === 'string') {
					return item.replace(/\"/g, '')
				}

				return item
			}) || result

		parser.set(key, resultValue)
		character.propertyDetail[key].value = resultValue
		character.property[key] = resultValue
	}

	Object.values(character.propertyDetail)
		.filter((d) => d.dependencies.indexOf(key) !== -1)
		.forEach((d) => reevaluateByKey(d.key, [...path, key]))
}

Object.keys(character.propertyDetail).forEach((key) => {
	reevaluateByKey(key, [])
})

const CharacterModuleTestingView = () => {
	const value = useMemo(
		() =>
			// JSON.stringify(
			//   transformCharacterFullDTOToCharacterFull(character),
			// 	null,
			// 	2
			// ),
			util.inspect(character.property, false, 5),
		[]
	)

	return (
		<>
			<div className='content no-header'>
				<pre>{value}</pre>
			</div>
		</>
	)
}

export default React.memo(CharacterModuleTestingView)
