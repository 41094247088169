import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import { Background } from '../../types/background.type'
import {
	CharacterAttachable,
	CharacterFeature,
} from '../../types/character.type'

import {
	getBackgroundAttachable,
	getBackgrounds,
} from '../../helpers/api.helper'

import { Alert, Button } from 'reactstrap'
import StyledReactSelect from '../../components/Select/StyledReactSelect'

import CBContext from './CBContext'
import CBFeatures from './CBFeatures'

const CBBackgrounds = (): JSX.Element => {
	const { context } = useContext(CBContext)
	const [selectedItem, setSelectedItem] = useState<Background | null>(null)

	const _selectGetOptionLabel = useCallback((item: Background) => item.name, [])
	const _selectIsOptionSelected = useCallback(
		(item: Background) => item.id === selectedItem?.id,
		[selectedItem]
	)

	const backgroundsQuery = useQuery<Background[]>(['backgrounds'], () =>
		getBackgrounds()
	)

	useEffect(() => {
		if (context.initBackgroundAttachableOAO && backgroundsQuery.data) {
			const found = backgroundsQuery.data.find(
				(item) =>
					item.id === context.initBackgroundAttachableOAO?.origin?.background
			)
			if (found) {
				setSelectedItem(found)
			}
		}
	}, [context.initBackgroundAttachableOAO, backgroundsQuery.data])

	if (backgroundsQuery.isLoading) {
		return <div>Loading...</div>
	}

	if (backgroundsQuery.isError) {
		return <div>Error</div>
	}

	return (
		<>
			{/* <Row>
				{query.data?.map((item) => (
					<Col xs={12} sm={6} md={4} lg={2} key={item.id}>
						{item.name}
					</Col>
				))}
			</Row> */}

			<StyledReactSelect
				options={backgroundsQuery.data}
				getOptionLabel={_selectGetOptionLabel}
				isOptionSelected={_selectIsOptionSelected}
				onChange={setSelectedItem}
				value={selectedItem}
			/>

			<div>
				{selectedItem && <CBBackgroundDetail background={selectedItem} />}
			</div>
		</>
	)
}

const StyledCBBackgroundDetailWrapper = styled.div`
	padding: 1em;
	margin-top: 1em;
	border-radius: 12px;
	background-color: rgba(82, 77, 47, 0.08);
`

const CBBackgroundDetail = ({ background }: { background: Background }) => {
	const { context, setContext } = useContext(CBContext)
	const [isInited, setInited] = useState<boolean>(false)
	const [doSubmitOnce, setDoSubmitOnce] = useState<boolean>(false)
	const [overridedFeaturesMap, setOverridedFeaturesMap] = useState<
		Record<string, CharacterFeature>
	>({})
	const [error, setError] = useState<string>('')

	const attachableQuery = useQuery<CharacterAttachable>(
		['background', background.id, 'attachable'],
		() => getBackgroundAttachable(background.id),
		{
			enabled: !!background,
		}
	)

	const handleFeatureChange = useCallback(
		(f: CharacterFeature) => {
			setOverridedFeaturesMap((prev) => ({
				...prev,
				[f.overridedCode || f.code]: f,
			}))
		},
		[setOverridedFeaturesMap]
	)

	const handleSubmit = useCallback(() => {
		for (const f of attachableQuery.data?.features || []) {
			if (
				f.options &&
				f.options.length > 0 &&
				(!overridedFeaturesMap[f.code] ||
					overridedFeaturesMap[f.code].amount !== f.amount)
			) {
				setError(`你要揀選 ${f.name} 中的其中 ${f.amount || 1}個選項。`)
				return
			}
		}

		setContext((prev) => ({
			...prev,
			background,
			backgroundAttachableOAO: {
				origin: attachableQuery.data,
				override: { features: Object.values(overridedFeaturesMap) },
			},
		}))
		setError('')
	}, [
		setContext,
		setError,
		background,
		attachableQuery.data,
		overridedFeaturesMap,
	])

	useEffect(() => {
		if (context.initBackgroundAttachableOAO) {
			const { override } = context.initBackgroundAttachableOAO
			const newOverridedFeaturesMap: Record<string, CharacterFeature> = {}
			for (const item of override?.features || []) {
				newOverridedFeaturesMap[item.overridedCode || item.code] = item
			}

			setOverridedFeaturesMap(newOverridedFeaturesMap)
			setDoSubmitOnce(true)
		}
		setInited(true)
	}, [context.initBackgroundAttachableOAO])

	useEffect(() => {
		if (doSubmitOnce && attachableQuery.data && isInited) {
			handleSubmit()
			setDoSubmitOnce(false)
		}
	}, [doSubmitOnce, attachableQuery.data, isInited])

	if (attachableQuery.isLoading || !isInited) {
		return (
			<StyledCBBackgroundDetailWrapper>
				Loading...
			</StyledCBBackgroundDetailWrapper>
		)
	}

	if (attachableQuery.isError) {
		return (
			<StyledCBBackgroundDetailWrapper>Error</StyledCBBackgroundDetailWrapper>
		)
	}

	return (
		<StyledCBBackgroundDetailWrapper>
			<div className='text-center'>
				<Button size='lg' color='primary' onClick={handleSubmit}>
					選擇此背景
				</Button>
			</div>
			<Alert isOpen={!!error} color='danger'>
				{error}
			</Alert>
			<p>
				選擇 <strong>{background.name}</strong>{' '}
				作為背景的話，角色會得到以下特性:
			</p>
			<CBFeatures
				features={attachableQuery.data?.features}
				hidden={['metadata']}
				sorting={['improvement', 'static', 'triggered', 'activated', 'mundane']}
				onFeatureChange={handleFeatureChange}
				overridedFeaturesMap={overridedFeaturesMap}
			/>
		</StyledCBBackgroundDetailWrapper>
	)
}

export default CBBackgrounds
