import React, { useMemo } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

import { Card } from 'reactstrap'

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	&.cmi-display-pill {
		display: inline-flex;
		padding: 2px 10px;
		background-color: rgba(0, 0, 0, 0.05);
		border-radius: 1em;
		margin-right: 5px;
		margin-bottom: 3px;
		font-size: 0.8em;

		.cmi-right {
			margin-left: 0.5em;
		}
	}

	.cmi-left {
		flex: 1;
	}

	.cmi-right {
		display: flex;
	}

	.cmi-subLabel {
		font-size: 12px;
		line-height: 12px;
		margin-top: -4px;
		transform: scale(0.8);
		transform-origin: top left;
	}
`

type Props = React.HTMLAttributes<HTMLDivElement> & {
	label: string
	subLabel?: string
	value?: string
	display?: 'block' | 'inline' | 'pill'
}

const CharacterModItem = ({
	label,
	subLabel,
	value,
	display,
	className,
	...others
}: Props) => {
	return (
		<Wrapper
			className={classnames(`cmi-display-${display}`, className)}
			{...others}
		>
			<div className='cmi-left'>
				<div className='cmi-label'>{label}</div>
				{subLabel && <div className='cmi-subLabel'>{subLabel}</div>}
			</div>
			{value && (
				<div className='cmi-right'>
					<div className='cmi-mod'>{value}</div>
				</div>
			)}
		</Wrapper>
	)
}

export default React.memo(CharacterModItem)

export const CMICard = styled(Card)`
	&.card {
		.card-header {
			padding-top: 8px;
			padding-bottom: 0px;
			font-size: 0.9em;
			color: rgba(0, 0, 0, 0.5);
		}

		.card-header + .card-body {
			padding-top: 0;
		}
	}
`
