import { Character } from './character.type'
import { City } from './city.type'
import { Entity } from './strapi/entity.type'
import { User } from './user.type'

export type GameCharacterAndReward = {
	id?: string
	character: Character
	gp: number
	xp: number
	items: { name: string; description?: string }[]
	remark: string
}

export type GameJournal = {
	id?: string
	subject?: string
	description: string
}

export enum GameStatus {
	Draft = 'draft',
	Published = 'published',
	Completed = 'completed',
}

export type Game = Entity & {
	code: string
	title: string
	description: string
	remark: string
	startAt: string
	endAt: string
	worldStartAt: string
	worldEndAt: string
	characters: Character[]
	lvMin: number
	lvMax: number
	capacityMin: number
	capacityMax: number
	tags: string
	publishedAt: string
	completedAt: string
	status: GameStatus
	googleCalendarEventId?: string
	googleCalendarEventUrl?: string

	dm: User
	city: City
	characterAndRewards: GameCharacterAndReward[]
	journals: GameJournal[]
}

export type Game_RequestDTO = Omit<
	Game,
	'dm' | 'city' | 'characterAndRewards'
> & {
	dm?: string
	city?: string
	characterAndRewards?: (Omit<GameCharacterAndReward, 'character'> & {
		character: string
	})[]
}
