import React from 'react'

import BiIcon from './BiIcon'
import { BiIconName } from './BiIconName'
import RPGIcon from './RPGIcon'
import { RPGIconName } from './RPGIconName'

export type IconProps = (
	| {
			category: 'ra'
			icon: RPGIconName
	  }
	| {
			category: 'bi'
			icon: BiIconName
	  }
) & {
	fixWidth?: boolean
	size?: 'normal' | 'lg' | '2x' | '3x' | '4x' | '5x'
}

const Icon = ({ category, icon, ...others }: IconProps): JSX.Element => {
	if (category === 'ra') {
		return <RPGIcon icon={icon as RPGIconName} {...others} />
	} else if (category === 'bi') {
		return <BiIcon icon={icon as BiIconName} {...others} />
	}

	return <></>
}

export default Icon
