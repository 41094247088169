import { Background } from '../types/background.type'
import {
	Character,
	Character_RequestDTO,
	CharacterAttachable,
	CharacterDetail,
	CharacterDetail_RequestDTO,
	CharacterLog,
} from '../types/character.type'
import { City } from '../types/city.type'
import { Cls } from '../types/cls.type'
import { Game, Game_RequestDTO } from '../types/game.type'
import { Race } from '../types/race.type'
import { Ruleset } from '../types/ruleset.type'
import { User } from '../types/user.type'

import api, { ExtendedAxiosRequestConfig } from '../services/api.service'

export const getCharactersByUsername = (
	username: string
): Promise<Character[]> => {
	return api.get<Character[]>('/characters', {
		params: {
			'player.username': username,
		},
	})
}
export const getCharactersBySearch = (q: string): Promise<Character[]> => {
	return api.get<Character[]>('/characters', {
		params: {
			_q: q,
		},
	})
}
export const getMyCharacters = (): Promise<Character[]> => {
	return api.get<Character[]>(`/characters/me`)
}
export const getCharacterById = (characterId: string): Promise<Character> => {
	return api.get<Character>(`/characters/${characterId}`)
}
export const postCharacter = (
	character: Partial<Character_RequestDTO>
): Promise<Character> => {
	return api.post<Character>(`/characters`, character)
}
export const putCharacter = (
	characterId: string,
	character: Partial<Character_RequestDTO>
): Promise<Character> => {
	return api.put<Character>(`/characters/${characterId}`, character)
}
export const getCharacterDetailById = (
	characterId: string
): Promise<CharacterDetail> => {
	return api.get<CharacterDetail>(`/characters/${characterId}/detail`)
}
export const postCharacterDetailById = (
	characterId: string,
	data: CharacterDetail_RequestDTO
): Promise<CharacterDetail> => {
	return api.post<CharacterDetail>(`/characters/${characterId}/detail`, data)
}
export const putCharacterDetailById = (
	characterId: string,
	data: CharacterDetail_RequestDTO
): Promise<CharacterDetail> => {
	return api.put<CharacterDetail>(`/characters/${characterId}/detail`, data)
}

export const getCharacterLogsByCharacterId = (
	characterId: string
): Promise<CharacterLog[]> => {
	return api.get<CharacterLog[]>(`/characters/${characterId}/logs`)
}

export const getGames = (): Promise<Game[]> => {
	return api.get<Game[]>(`/games`)
}
export const getGamesByDateRange = (
	start: Date,
	end: Date,
	config: ExtendedAxiosRequestConfig = {}
): Promise<Game[]> => {
	return api.get<Game[]>(`/games`, {
		params: {
			startAt_gte: start.toISOString(),
			startAt_lte: end.toISOString(),
		},
		...config,
	})
}
export const getGameById = (
	id: string,
	config: ExtendedAxiosRequestConfig = {}
): Promise<Game> => {
	return api.get<Game>(`/games/${id}`, config)
}
export const postGame = (
	game: Game_RequestDTO,
	config: ExtendedAxiosRequestConfig = {}
): Promise<Game> => {
	return api.post<Game>(`/games`, game, config)
}
export const patchGameToDraftById = (id: string): Promise<Game> => {
	return api.patch<Game>(`/games/${id}/draft`)
}
export const patchGameToPublishedById = (id: string): Promise<Game> => {
	return api.patch<Game>(`/games/${id}/publish`)
}
export const patchGameToCompletedById = (id: string): Promise<Game> => {
	return api.patch<Game>(`/games/${id}/complete`)
}
export const putGameById = (
	id: string,
	game: Game_RequestDTO,
	config: ExtendedAxiosRequestConfig = {}
): Promise<Game> => {
	return api.put<Game>(`/games/${id}`, game, config)
}

export const getCities = (
	config: ExtendedAxiosRequestConfig = {}
): Promise<City[]> => {
	return api.get<City[]>(`/cities`, config)
}

export const getDMs = (
	config: ExtendedAxiosRequestConfig = {}
): Promise<User[]> => {
	return api.get<User[]>(`/users`, {
		params: {
			'role.name': 'Dungeon Master',
		},
		...config,
	})
}

export const getAnythingbySearch = (
	q: string
): Promise<{
	characters: Character[]
	games: Game[]
	users: User[]
}> => {
	return api.get<{
		characters: Character[]
		games: Game[]
		users: User[]
	}>('/global/search', {
		params: {
			_q: q,
		},
	})
}

export const getRaces = (
	config: ExtendedAxiosRequestConfig = {
		params: {
			_sort: 'name:asc',
		},
	}
): Promise<Race[]> => {
	return api.get<Race[]>(`/races`, config)
}

export const getRaceAttachable = (
	raceId: string
): Promise<CharacterAttachable> => {
	return api.get<CharacterAttachable>(`/races/${raceId}/attachable`)
}

export const getClses = (
	config: ExtendedAxiosRequestConfig = {}
): Promise<Cls[]> => {
	return api.get<Cls[]>(`/clses`, config)
}

export const getClsAttachable = (
	clsId: string
): Promise<CharacterAttachable> => {
	return api.get<CharacterAttachable>(`/clses/${clsId}/attachable`)
}

export const getBackgrounds = (
	config: ExtendedAxiosRequestConfig = {}
): Promise<Background[]> => {
	return api.get<Background[]>(`/backgrounds`, config)
}

export const getBackgroundAttachable = (
	backgroundId: string
): Promise<CharacterAttachable> => {
	return api.get<CharacterAttachable>(`/backgrounds/${backgroundId}/attachable`)
}

export const getRulesets = (
	config: ExtendedAxiosRequestConfig = {}
): Promise<Ruleset[]> => {
	return api.get<Ruleset[]>(`/rulesets`, config)
}
export const getRulesetAttachable = (
	rulesetId: string
): Promise<CharacterAttachable> => {
	return api.get<CharacterAttachable>(`/rulesets/${rulesetId}/attachable`)
}

export const getRuleset_DnD5e = (
	config: ExtendedAxiosRequestConfig = {}
): Promise<Ruleset> => {
	return api.get<Ruleset>(`/rulesets/dnd5e`, config)
}

export const setAuthorization = api.setAuthorization
export const removeAuthorization = api.removeAuthorization
